import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';


class EmployeeMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false
        };
    }

    componentWillMount() {
        let itemInfo = this.props.info;
        Object.keys(itemInfo).map(key =>  this.setState({[key]:itemInfo[key]}));
        this.setState({name: this.props.info.firstName +' '+this.props.info.lastName.replace(' ', '')})
    }

    handleEdit(){
        if (this.props.isAdmin) {
            if (this.state.editing) {
                let docRef = db.collection('employees').doc(this.state.workEmail);
                let {editing, name, ...stateCopy} = {...this.state};
                let nameSplit = name.split(' ');
                let firstName = nameSplit.length > 1 ? nameSplit[0] : this.props.info.firstName;
                let lastName = nameSplit.length > 1 ? nameSplit[1] : this.props.info.lastName;
                console.log(nameSplit,firstName,lastName);
                docRef.update({...stateCopy, firstName, lastName}).then(() => console.log('doc has been updated')).catch(e => console.error(e));
                this.setState({editing: !this.state.editing})
            } else {
                this.setState({editing: !this.state.editing})
            }
        }
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

      copyToClipboard () {
        const el = document.createElement('textarea');
        el.value = this.props.info.phone;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert(`Phone Number has been copied ${this.props.info.phone}`)
    };


    render() {
        let backgroundColor = { border: `2px solid ${this.props.info.color}`, backgroundColor: "RGBA(64, 76, 95, 1.00)"};
        let logo = require('../../assets/FreeAgentLogo-01.png');
        let logoStyle1 = {height: '17px', width: 'auto', marginRight: '3px'};
        let emailStyle = {height: '17px'};
        let sanitizedPhone = this.state.phone.replace('+','').replace('(','').replace(')','').replace(' ','').replace('-','');
        let whatappLink = 'https://wa.me/' + sanitizedPhone;
        let whatappLogo = require('../../assets/whatsapp.svg');
        let gmailIcon = require('../../assets/gmail.svg');
        let copyIcon = require('../../assets/copy.svg');
        let phoneIsAvalable = this.props.info.phone.replace(' ','') !== '';
        return (
            <div className="employee-mobile-container">
                <div className="employee-mobile" style={backgroundColor}>
                    <img alt="" src={logo} style={logoStyle1} onClick={() => this.handleEdit()}/>
                    <span style={{fontSize: '14px', marginRight: '10px'}}>{this.props.info.firstName} {this.props.info.lastName}</span>
                    <span style={{fontSize: '13px', marginRight: '10px', minWidth: '30px'}}>{this.props.info.officeLocation}</span>
                    <span><a href={"mailto:" + this.props.info.workEmail}><img style={emailStyle} src={gmailIcon}/></a></span>
                    <span style={{fontSize: '15px', justifyContent: 'center'}}><a href={whatappLink} target="_blank" style={{textDecoration:'none'}}>{phoneIsAvalable &&<img style={emailStyle} src={whatappLogo}/>}</a></span>
                    <span style={{marginRight: '7px'}}>{phoneIsAvalable && <img style={emailStyle} src={copyIcon} onClick={e => this.copyToClipboard(e)}/>}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    employees: state.store.allEmployees,
    employeesFiltered: state.store.filteredEmployees,
    candidates: state.store.allCandidates,
    employeesCandidates: state.store.filteredCandidates,
    assignedInventory: state.inventory.assignedInventory,
    inventoryTabs: state.store.inventoryGroups,
    accountTabs: state.accounts.accountGroups,
    isAdmin: state.store.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMobile);
