import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';


class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false
        };
    }

    componentWillMount() {
        let itemInfo = this.props.info;
        Object.keys(itemInfo).map(key =>  this.setState({[key]:itemInfo[key]}));
        this.setState({name: this.props.info.firstName +' '+this.props.info.lastName.replace(' ', '')})
    }

    handleEdit(){
        if (this.props.isAdmin) {
            if (this.state.editing) {
                let docRef = db.collection('employees').doc(this.state.workEmail);
                let {editing, name, ...stateCopy} = {...this.state};
                let nameSplit = name.split(' ');
                let firstName = nameSplit.length > 1 ? nameSplit[0] : this.props.info.firstName;
                let lastName = nameSplit.length > 1 ? nameSplit[1] : this.props.info.lastName;
                console.log(nameSplit,firstName,lastName);
                docRef.update({...stateCopy, firstName, lastName}).then(() => console.log('doc has been updated')).catch(e => console.error(e));
                this.setState({editing: !this.state.editing})
            } else {
                this.setState({editing: !this.state.editing})
            }
        }
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    render() {
        let backgroundColor = { border: `2px solid ${this.props.info.color}`, backgroundColor: "RGBA(64, 76, 95, 1.00)"};
        let logo = require('../../assets/FreeAgentLogo-01.png');
        let logoStyle1 = {height: '25px', width: 'auto', marginRight: '5px'};
        let assignedInventoryFields;
        if(this.props.info && this.props.info.assigned) {
            assignedInventoryFields = this.props.info.assigned.map((field,i) => {
                if(field.group) {
                    let src = '';
                    let serial =  field.deviceid || field.serial || field.group;
                    this.props.inventoryTabs.map(tab => {
                        if (tab.name === field.group) {
                            src = tab.icon;
                        }
                    });
                    return (<span key={i} style={{marginRight: "10px"}}><img alt="" style={{height: "20px", position: "relative", top: "4px",marginRight: "5px"}} src={src}/>{serial}</span>)
                }
            });
        }

        let assignedAccountFields;
        if(this.props.info && this.props.info.assignedAccounts) {
            assignedAccountFields = this.props.info.assignedAccounts.map((field,i) => {
                if(field.group) {
                    let src = '';
                    let serial =  field.username || field.email || field.id || field.group;
                    this.props.accountTabs.map(tab => {
                        if (tab.name === field.group) {
                            src = tab.icon;
                        }
                    });
                    return (<span key={i} style={{marginRight: "10px"}}><img alt="" style={{height: "20px", position: "relative", top: "4px",marginRight: "5px"}} src={src}/>{serial}</span>)
                }
            });
        }

        let emailToStyle = {color: "white", textDecoration: "none", overflow: "hidden"};
        let departmentStyle = { color: this.props.info.color, textTransform: "capitalize", fontWeight: "bold"};
        let sanitizedPhone = this.state.phone.replace('+','').replace('(','').replace(')','').replace(' ','').replace('-','');
        let whatappLink = 'https://wa.me/' + sanitizedPhone;
        return (
            <div className="employee-container">
                <div className="employee" style={backgroundColor}>
                    <img alt="" src={logo} style={logoStyle1} onClick={() => this.handleEdit()}/>
                    <span><b>{this.props.order}</b></span>
                    {!this.state.editing ? <span><b>{this.props.info.firstName} {this.props.info.lastName}</b></span> :
                        <input name='name' value={this.state.name || ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ? <span>{this.props.info.officeLocation}</span> :
                        <input name='officeLocation' value={this.state.officeLocation ? this.state.officeLocation : ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ? <span>{this.props.info.companyRole}</span> :
                        <input name='companyRole' value={this.state.companyRole ? this.state.companyRole : ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ? (<span><a href={"mailto:" + this.props.info.workEmail}>
                        {this.props.info.workEmail}</a> </span> )
                        : <input  name='workEmail' value={this.state.workEmail ? this.state.workEmail : ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ? <span style={{fontSize: '15px', justifyContent: 'center'}}><a href={whatappLink} target="_blank" style={{textDecoration:'none'}}>{this.props.info.phone}</a></span> :
                        <input  name='phone' value={this.state.phone ? this.state.phone : ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ? <span>{this.props.info.dateJoined}</span> :
                        <input name='dateJoined' value={this.state.dateJoined ? this.state.dateJoined : ''} onChange={e => this.handleInputChange(e)}/>}
                    {!this.state.editing ?
                        <span style={departmentStyle}>{this.props.info.department}</span> :
                        <input name='department' style={{maxWidth: '70%'}} value={this.state.department ? this.state.department : ''} onChange={e => this.handleInputChange(e)}/>}
                </div>
                <div className="employee-additional-info" style={{ border: `2px solid ${this.props.info.color}`}}>
                    <div>{assignedInventoryFields}</div> <div>{assignedAccountFields}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    employees: state.store.allEmployees,
    employeesFiltered: state.store.filteredEmployees,
    candidates: state.store.allCandidates,
    employeesCandidates: state.store.filteredCandidates,
    assignedInventory: state.inventory.assignedInventory,
    inventoryTabs: state.store.inventoryGroups,
    accountTabs: state.accounts.accountGroups,
    isAdmin: state.store.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
