import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';
import Employee from "./Employee";
import EmployeeMobile from "./EmployeeMobile";
import Candidate from "../candidates/Candidate";


class Employees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmployee: true,
            employeesIsOpen: false,
            candidatesIsTrue: false
        };
    }

    componentWillMount() {

    }

    handleSlider(e) {
        document.querySelector(".employees-slider").classList.toggle("employees");
        let label = document.querySelector('.employees-label');
        label.style.opacity = 0;
        if (label.innerText === 'Employees') {
            label.style.color = "white";
            label.style.left = "148px";
            this.setState({isEmployee: false});
            setTimeout(() => {
                label.innerText = 'Candidates';
                label.style.opacity = 1;
            }, 300)
        } else {
            label.style.left = "145px";
            this.setState({isEmployee: true});
            setTimeout(() => {
                label.innerText = 'Employees';
                label.style.opacity = 1;
                label.style.color = "white";
            }, 300)
        }
    }

    render() {
        let employees;
        let candidates;
        let isEmployees = this.state.isEmployee;
        let firstElement = this.props.employeesFiltered &&  this.props.employeesFiltered[0] ? this.props.employeesFiltered[0].color : "rgba(255,255,255,.3)";
        let labelStyle = {color: `${firstElement}`};
        let isMobile = window.innerWidth < 600;
        if(!isMobile) {
            employees = this.props.employeesFiltered.map((employee, i) => <Employee info={employee} inventory={employee.assigned} key={i} order={i + 1}/>);
        } else {
            employees = this.props.employeesFiltered.map((employee, i) => <EmployeeMobile info={employee} inventory={employee.assigned} key={i} order={i + 1}/>);
        }

        candidates = this.props.employeesCandidates.map((candidate, i) => <Candidate info={candidate} key={i} order={i + 1}/>);
        return (
            <div className="employees-list-container">
                {this.state.candidatesIsTrue && <div className="employees-header">
                    <span>Employees</span>
                    <label className="switch-employees">
                        <input type="checkbox"/>
                        <span className="employees-slider round"  id="slider-employees" onClick={e => this.handleSlider(e)}/>
                    </label>
                </div>}
                {isEmployees ? employees : candidates}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    employees: state.store.allEmployees,
    employeesFiltered: state.store.filteredEmployees,
    candidates: state.store.allCandidates,
    employeesCandidates: state.store.filteredCandidates
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
