//let adminList = ['april@freeagentsoftware.com', 'vero@freeagentsoftware.com'];

export default (state = {
    activeList: [],
    settingsIsOpen: false,
    isInventory: true,
    settingsFields: [],
    updatedSettings: [],
    refresh: true,
    lastItemAdded:{},
    assignedInventory: []
}, action) => {
    switch (action.type) {
        case 'LOAD_INVENTORY':
            let copyState = {...state};
            copyState[action.inventoryList.name.toLowerCase()] = action.inventoryList.groupItems;
            return {
                ...copyState
            };
        case 'UPDATE_INVENTORY':
            let inventoryUpdateState = {...state};
            let word = '';
            let lastElementWord = '';
            Object.keys(action.itemInfo.info).map(key => key !== 'timeframe' ? word = word + action.itemInfo.info[key] : null);
            Object.keys(state.lastItemAdded).map(key => key !== 'timeframe' ? lastElementWord = lastElementWord + state.lastItemAdded[key] : null);
            if(word.trim() !== '' && word !== lastElementWord) {
                if(!state.activeList) {
                    inventoryUpdateState.activeList = [action.itemInfo.info];
                } else {
                    inventoryUpdateState.activeList = [...state.activeList, action.itemInfo.info];
                }

                if(!inventoryUpdateState[action.itemInfo.name] && action.itemInfo.name) {
                    inventoryUpdateState[action.itemInfo.name.toLowerCase()] = [action.itemInfo.info];
                } else {
                    inventoryUpdateState[action.itemInfo.name.toLowerCase()] = [...state[action.itemInfo.name.toLowerCase()], action.itemInfo.info];
                }
                inventoryUpdateState.lastItemAdded = action.itemInfo.info;
            }
            return {
                ...inventoryUpdateState
            };
        case 'LOAD_FIRST':
            return {
                ...state,
                activeList: action.groupList
            };
        case 'OPEN_SETTINGS':
            return {
                ...state,
                settingsIsOpen:true
            };
        case 'CLOSE_SETTINGS':
            return {
                ...state,
                settingsIsOpen:false
            };
        case 'IS_INVENTORY':
            return {
                ...state,
                isInventory:!state.isInventory
            };
        case 'LOAD_INVENTORY_SETTINGS':
            return {
                ...state,
                settingsFields: action.settings,
                updatedSettings: action.settings,
                refresh: !state.refresh
            };
        case 'UPDATE_SETTINGS':
            let settingsStateCopy = {...state};
            let settingFields = state.updatedSettings || [];
            let onlyLabels = settingFields.map(field => field.label);
            if(!onlyLabels.includes(action.newField.label)) {
                settingsStateCopy.updatedSettings = [...settingFields, action.newField];
            } else {
                settingsStateCopy.updatedSettings = [...settingFields];
            }
            settingsStateCopy.refresh = !state.refresh;
            return {
                ...settingsStateCopy
            };
        case 'EDIT_INVENTORY_FIELD':
            let editingCopy = {...state};
            editingCopy[action.updatedGroupInfo.selected] = action.updatedGroupInfo.editedArray;
            editingCopy.refresh = !editingCopy.refresh;
            editingCopy.activeList = [...editingCopy[action.updatedGroupInfo.selected]];
            return {
                ...editingCopy
            };
        case 'INVENTORY_ASSIGNED':
            let assignedCopy = {...state};
            assignedCopy.assignedInventory = [...assignedCopy.assignedInventory, {[action.assignedToInfo.email]: action.assignedToInfo.data}];
            return {
                ...assignedCopy
            };
        default:
            return state;
    }
};
