import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';
import Candidate from "./Candidate";
import Employee from "../employees/Employee";


class Candidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {

    }

    render() {
        let candidates;
        candidates = this.props.employeesCandidates.map((candidate, i) => <Candidate info={candidate} key={i} order={i + 1}/>);
        return (
            <div className="candidates">
                <div className="candidates-header">Candidates</div>
                {candidates}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    candidates: state.store.allCandidates,
    employeesCandidates: state.store.filteredCandidates
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
