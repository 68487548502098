import {firebase, provider} from "../DB/db";


export const startLogin = () => {
    return () => {
        return firebase.auth().signInWithPopup(provider);
    }
};

export const startLoginRedirect = () => {
    return () => {
        return firebase.auth().signInWithRedirect(provider)
    }
};
