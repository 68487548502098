import React, {Component} from 'react';
import {connect} from 'react-redux';
import uuid4 from 'uuid4';
import uniqid from "uniqid";
import SelectItem from "./SelectedItem";
import db, { firebase } from '../../DB/db';
import {updateInventory, openSettings, addAssignedToInventory} from "../../actions/actions";
import SelectedAccountItem from "../accounts/SelectedAccountItem";
import {updateAccounts} from "../../actions/accounts";


class SelectedGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedList: [],
            formIsOpen: false,
        };
    }

    openForm() {
        document.querySelector('#addItemForm').style.display = 'flex';
        this.setState({formIsOpen: true});
    }
    closeForm() {
        document.querySelector('#addItemForm').style.display = 'none';
        this.setState({formIsOpen: false});
    }

    openSettings() {
        this.props.openSettings();
    }


    addItem(e) {
            let allInfo = {};
            let allInputValues = document.querySelectorAll('#addItemForm > input');
            allInputValues.forEach(field => {
                return allInfo[field.name.replace(' ', '')] = field.value;
            });
            if (document.querySelector('#addItemForm > select')) {
                allInfo.assignedto = document.querySelector('#addItemForm > select').value;
            }
            let employeeEmail = document.querySelector('#addItemForm > select').selectedOptions[0].dataset.email;
            allInfo.timeframe = Date.now().toString();
            let itemInfo = {};
            itemInfo.info = allInfo;
            let id = allInfo.serial && allInfo.serial !== '' ? allInfo.serial : allInfo.timeframe;
            if (employeeEmail) {
                allInfo.email = employeeEmail;
                allInfo.uniqId = `${uuid4()}`;
                if (this.props.isInventory) {
                    let selectedGroup = this.props.selectedGroup;
                    db.collection(`inventoryGroups/list/${selectedGroup}`).add({})
                        .then(data => {
                            db.doc(data.path)
                                .set(allInfo)
                                .then(() => console.log('saved to the DB'))
                                .catch(err => console.error(err));
                        });
                    itemInfo.name = selectedGroup;
                    this.props.updateInventory(itemInfo);
                    let employeeRef = db.collection(`employees`).doc(employeeEmail);
                    let infoToDb = {...itemInfo.info};
                    infoToDb.group = selectedGroup;
                    this.props.addAssignedToInventory({email:employeeEmail, data:infoToDb, type: "inventory"});
                    return employeeRef.collection("inventoryAssigned").add({})
                        .then(data => {
                            db.doc(data.path)
                                .set({...infoToDb})
                                .then(() => console.log(''))
                                .catch(err => console.error(err));
                        })

                } else {
                    let docRef = db.doc(`accountGroups/list/${this.props.selectedAccount}/${id}`);
                    docRef.set(allInfo).then(() => console.log('saved to the DB')).catch(err => console.error(err));
                    itemInfo.name = this.props.selectedAccount;
                    this.props.updateAccounts(itemInfo);
                    let employeeRef = db.collection(`employees`).doc(employeeEmail);
                    let infoToDb = {...itemInfo.info};
                    infoToDb.group = this.props.selectedAccount;
                    infoToDb.uniqId = `${uuid4()}`;
                    infoToDb.email = employeeEmail;
                    this.props.addAssignedToInventory({email:employeeEmail, data:infoToDb, type: "account"});
                    return employeeRef.collection("accountsAssigned").add({})
                        .then(data => {
                            db.doc(data.path)
                                .set({...infoToDb})
                                .then(() => console.log(''))
                                .catch(err => console.error(err));
                        })
                }
            } else {
                alert('please assign to an employee');
            }
    }

    static getTotalPrice(term, price, amount) {
        let total = '';
        switch (term.toLowerCase()) {
            case 'monthly':
                total = amount * price;
                break;
            case 'annual':
                total = amount * price;
                break;
            case 'lifetime':
                total = price;
                break;
            default:
                total = amount * price;
        }
        return total;
    }

    render() {
        let settingsIcon = require("../../assets/settings-work-tool.svg");
        let settingsStyle = {height: "18px", width: "auto"};
        let inventoryLabels;
        let onlyWithDisplay = [];
        let tabInfo = this.props.selectedTabInfo;
        this.props.settingsFields.map(field => {
            return field.display ? onlyWithDisplay=[...onlyWithDisplay,field.label] : null
        });
        if(this.props.settingsFields) {
            inventoryLabels = onlyWithDisplay.map((field,i) => (<span className="labels" key={uuid4()}>{field}</span>))
        }

        let accountLabels;
        let onlyAccountWithDisplay = [];
        if(this.props.settingsAccount) {
            this.props.settingsAccount.map(field => field.display ? onlyAccountWithDisplay=[...onlyAccountWithDisplay,field.label] : null);
            accountLabels = onlyAccountWithDisplay.map((field,i) => (<span className="labels" key={uuid4()}>{field}</span>))
        }

        let inventory;
        if(this.props.activeList) {
            inventory = this.props.activeList.sort((a,b) => a - b).map((item, i) => <SelectItem item={item} key={uuid4()} index={i}/>)
        }
        let accounts;
        let totalSum = 0;
        if(this.props.activeAccountsList) {
            accounts = this.props.activeAccountsList.map((item, i) => {
                if(item.price && item.price !== '') {
                    totalSum = totalSum + parseInt(item.price, 10);
                } else {
                    totalSum = totalSum + parseInt(tabInfo.price,10);
                }
                return (<SelectedAccountItem item={item} key={uuid4()} index={i}/>)
            })
        }

        let assignedOptions;
        if(this.props.allEmployees) {
            assignedOptions =  this.props.allEmployees.map((employee,i) => {
                return (<option data-email={employee.workEmail} key={i}>{employee.firstName} {employee.lastName}</option>)
            })
        }
        let inputFields;

        if(this.props.settingsFields) {
            inputFields = this.props.settingsFields.map((field,i) => {
                if(field.display) {
                    let label = field.label.replace(' ', '').toLowerCase();
                    let returnField;
                    if(label === 'assignedto') {
                        returnField = (<select
                            key={i}
                        >
                            <option key={2434}>Select an Employee</option>
                            {assignedOptions}
                        </select>)
                    } else {
                        returnField = (<input
                            key={uuid4()}
                            name={label}
                            id={label}
                            placeholder={field.placeholder}
                        />)

                    }
                    return returnField
                }
            })
        }
        let inputAccountFields;
        if(this.props.settingsAccount) {
            inputAccountFields = this.props.settingsAccount.map((field,i) => {
                if(field.display) {
                    let label = field.label.toLowerCase();
                    let returnField;
                    if (label.replace(' ', '') === 'assignedto') {
                        returnField = (<select
                            key={i}
                        >
                            <option key={2434}>Select an Employee</option>
                            {assignedOptions}
                        </select>)
                    } else {
                        returnField = (<input
                            key={uuid4()}
                            name={label}
                            id={label}
                            placeholder={field.placeholder}
                        />)

                    }
                    return returnField;
                }
            })
        }

        let activListLenght = this.props.isInventory ? this.props.activeList.length : this.props.activeAccountsList.length;
        let tabName = tabInfo.name ? tabInfo.name.substring(0,1).toUpperCase() + tabInfo.name.substring(1) : '';
        let adminName = tabInfo.admin && !this.props.isInventory? 'Admin is' + ' ' + tabInfo.admin.substring(0,1).toUpperCase() + tabInfo.admin.substring(1) : '';
        let accountsWord = !this.props.isInventory ? 'Accounts ' : '';
        let term = tabInfo.term && tabInfo.price && activListLenght ?  SelectedGroup.getTotalPrice(tabInfo.term,tabInfo.price, activListLenght) : '';
        let totalPrice = !this.props.isInventory && tabInfo.price ? 'Total price' + ' ' + tabInfo.term + ' ' + 'is' + ' ' + totalSum + '$' : '';
        return (
            <div className="selected-tab-content">
                <div className="selected-item">
                    {this.props.isInventory ? inventoryLabels : accountLabels }
                    <div className="settingsButton" onClick={e => this.openSettings(e)}>
                        <img className="addButton"
                             alt=""
                             src={settingsIcon}
                             style={settingsStyle}
                             onClick={e => this.openSettings(e)}/>
                    </div>
                </div>
                {this.props.isInventory ? inventory : accounts}
                <form id="addItemForm">
                    {this.props.isInventory ? inputFields : inputAccountFields}
                    <div className="addButton plusButton" onClick={(e) => this.addItem(e)}/>
                    <div className="addButton xButton" onClick={e => this.closeForm(e)} />
                </form>
                {!this.state.formIsOpen && <div className="plus-add-item" onClick={(e) => this.openForm(e)} />}
                <div className="bottom-info">
                    <span>Total {tabName} {accountsWord}{activListLenght} </span>
                    {totalPrice !== '' && <span>{totalPrice}</span>}
                    {adminName !== '' && <span>{adminName}</span>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.inventory,
    activeList: state.inventory.activeList,
    selectedGroup: state.store.selectedGroup,
    activeAccountsList: state.accounts.activeAccountsList,
    selectedAccount: state.accounts.selectedAccount,
    accounts: state.accounts,
    isInventory: state.inventory.isInventory,
    settingsFields: state.inventory.updatedSettings,
    settingsAccount: state.accounts.updatedSettings,
    allEmployees: state.store.allEmployees
});

const mapDispatchToProps = (dispatch) => ({
    updateInventory: (itemInfo) => dispatch(updateInventory(itemInfo)),
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo)),
    openSettings:() => dispatch(openSettings()),
    addAssignedToInventory: (info) => dispatch(addAssignedToInventory(info))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedGroup);
