import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';


class Candidate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
        let employeeRef = db.collection(`employees`);
        employeeRef.get()
            .then(querySnapshot => {
                return querySnapshot.forEach(employee => null);
            })
    }

    render() {
        let style = {border: `2px solid ${this.props.info.color}`, backgroundColor: "RGBA(64, 76, 95, 1.00)"};
        let additionalStyle = {border: `2px solid ${this.props.info.color}`, backgroundColor: "white"};
        let backgroundColor = { border: `2px solid ${this.props.info.color}`, backgroundColor: "RGBA(64, 76, 95, 1.00)"};

        return (
            <div className="candidate-container" >
                <div className="candidates" style={backgroundColor}>
                    <span><b>{this.props.order}</b></span>
                    <span><b>{this.props.info.firstName} {this.props.info.lastName}</b></span>
                    <span>{this.props.info.officeLocation}</span>
                    <span>{this.props.info.companyRole}</span>
                    <span><a style={{color: "white", textDecoration: "none", overflow: "hidden"}}
                             href={"mailto:" + this.props.info.workEmail}>
                    {this.props.info.workEmail}</a>
                </span>
                    <span>{this.props.info.phone}</span>
                    <span style={{ color: this.props.info.color, textTransform: "capitalize", fontWeight: "bold"}}>{this.props.info.department}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
