export const getDepartmentColor = (department) => {
    let color = '';
    switch(department) {
        case "marketing":
            color = "#FCEA3A";
            break;
        case "developers":
            color = "#4AAF4E";
            break;
        case "success":
            color = "#1F94F2";
            break;
        case "qa":
            color = "#03BAD3";
            break;
        case "product":
            color = "#FD9602";
            break;
        case "sales":
            color = "#CBDA38";
            break;
        default:
            color = "#FB5621";
    }
    return color;
};
