import React, {Component} from 'react';
import {connect} from 'react-redux';
import {openModal,openMinusModal, closeModal, saveDepartment, filterEmployees, filterCandidates, addEmployee} from "../actions/actions";

class Header extends Component {
    state = {
    };

    addEmployee(e) {
        this.props.saveDepartment(e.target.dataset.department);
        this.props.openModal()
    }
    minusEmployee(e) {
        this.props.saveDepartment(e.target.dataset.department);
        this.props.openMinusModal()
    }

    filterByDepartment(e) {
        let className = e.target.classList.value;
        if(className !== 'minus' && className !== 'plus') {
            let department = e.target.dataset.department;
            let employees = this.props.allEmployees;
            let candidates = this.props.allCandidates;
            if (department === 'total') {
                this.props.filterEmployees(employees);
                this.props.filterCandidates(candidates);
            } else {
                if (department) {
                    let filteredByDepartment = [];
                    employees.map(employee => employee.department === department ? filteredByDepartment.push(employee) : null);
                    this.props.filterEmployees(filteredByDepartment);
                    let filteredCandidates = [];
                    candidates.map(candidate => candidate.department === department ? filteredCandidates = [...filteredCandidates, candidate] : null);
                    this.props.filterCandidates(filteredCandidates);
                }
            }
        }
    }

    render() {
        let isAdmin = this.props.isAdmin;
        let logo = require('../assets/FreeAgentLogo-01.png');
        let logoStyle1 = {height: '20px', width: 'auto'};
        let width = window.innerWidth;
        let isMobile = window.innerWidth < 600;
        console.log(width,isMobile);
        return (
             <div className="admin-header">
                 {!isMobile && <>
                <div className="total" data-department="total" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="total-header" data-department="total" onClick={(e) => this.filterByDepartment(e)}>FreeAgent</div>
                    <div className="total-employees">{this.props.allEmployees.length}</div>
                </div>
                <div className="development" data-department="developers" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="developers-header" data-department="developers" onClick={(e) => this.filterByDepartment(e)}>Developers</div>
                    <div className="developers-employees flex-column">
                        {isAdmin &&  <span className="minus" data-department="developers" onClick={(e) => this.minusEmployee(e)}>-</span> }
                        {this.props.developers.length}
                        {isAdmin && <span className="plus" data-department="developers" onClick={(e) => this.addEmployee(e)}>+</span> }
                    </div>
                </div>
                <div className="marketing" data-department="marketing" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="marketing-header" data-department="marketing" onClick={(e) => this.filterByDepartment(e)}>Marketing</div>
                    <div className="marketing-employees flex-column">
                        {isAdmin && <span className="minus" data-department="marketing" onClick={(e) => this.minusEmployee(e)}>-</span>}
                        {this.props.marketing.length}
                        {isAdmin && <span className="plus" data-department="marketing" onClick={(e) => this.addEmployee(e)}>+</span>}
                    </div>
                </div>
                <div className="product" data-department="product" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="product-header" data-department="product" onClick={(e) => this.filterByDepartment(e)}>Product</div>
                    <div className="product-employees flex-column">
                        {isAdmin && <span className="minus" data-department="product" onClick={(e) => this.minusEmployee(e)}>-</span>}
                        {this.props.product.length}
                        {isAdmin && <span className="plus" data-department="product" onClick={(e) => this.addEmployee(e)}>+</span>}
                    </div>
                </div>
                <div className="qa" data-department="qa" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="qa-header" data-department="qa" onClick={(e) => this.filterByDepartment(e)}>Qa</div>
                    <div className="qa-employees flex-column">
                        {isAdmin && <span className="minus" data-department="qa" onClick={(e) => this.minusEmployee(e)}>-</span> }
                        {this.props.qa.length}
                        {isAdmin && <span className="plus" data-department="qa" onClick={(e) => this.addEmployee(e)}>+</span> }
                    </div>
                </div>
                <div className="sales" data-department="sales" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="sales-header" data-department="sales" onClick={(e) => this.filterByDepartment(e)}>Sales</div>
                    <div className="sales-employees flex-column">
                        {isAdmin && <span className="minus" data-department="sales" onClick={(e) => this.minusEmployee(e)}>-</span>}
                        {this.props.sales.length}
                        {isAdmin &&<span className="plus" data-department="sales" onClick={(e) => this.addEmployee(e)}>+</span>}
                    </div>
                </div>
                <div className="success" data-department="success" onClick={(e) => this.filterByDepartment(e)}>
                    <div className="success-header" data-department="success" onClick={(e) => this.filterByDepartment(e)}>Success</div>
                    <div className="succeess-employees flex-column">
                        {isAdmin && <span className="minus" data-department="success" onClick={(e) => this.minusEmployee(e)}>-</span>}
                        {this.props.success.length}
                        {isAdmin && <span className="plus" data-department="success" onClick={(e) => this.addEmployee(e)}>+</span>}
                    </div>
                </div>
               </>}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAdmin: state.store.isAdmin,
    allEmployees: state.store.allEmployees,
    allCandidates: state.store.allCandidates,
    marketing: state.store.marketing,
    sales: state.store.sales,
    qa: state.store.qa,
    product: state.store.product,
    success: state.store.success,
    developers: state.store.developers
});

const mapDispatchToProps = (dispatch) => ({
    openModal: () => dispatch(openModal()),
    openMinusModal: () => dispatch(openMinusModal()),
    saveDepartment: (department) => dispatch(saveDepartment(department)),
    filterEmployees: (filteredEmployees) => dispatch(filterEmployees(filteredEmployees)),
    filterCandidates: (filteredCandidates) => dispatch(filterCandidates(filteredCandidates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
