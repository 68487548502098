//let adminList = ['april@freeagentsoftware.com', 'vero@freeagentsoftware.com'];


let email = '';

export default (state = {
    agentInfo: {},
    loadPage: false,
    isAdmin: false,
    modalIsOpen: false,
    minusModalIsOpen: false,
    firstForm: {},
    allEmployees: [],
    allCandidates: [],
    filteredEmployees: [],
    filteredCandidates: [],
    marketing: [],
    sales: [],
    qa: [],
    success: [],
    product: [],
    developers: [],
    inventoryGroups: [],
    selectedGroup: 'notebooks',
    allInventory: {}
}, action) => {
    switch (action.type) {
        case 'LOAD_EMPLOYEES':
            let employees = action.employees.sort((a,b) => a.department.localeCompare(b.department));
            return {
                ...state,
                allEmployees: employees,
                filteredEmployees:employees
            };
        case 'REMOVE_EMPLOYEE':
            let removeState = {...state};
            removeState[action.info.department] = action.info.newArray;
            return {
                ...removeState
            };
        case 'ALL_DEPARTMENTS':
            return {
                ...state,
                marketing: action.split.marketing,
                sales: action.split.sales,
                qa: action.split.qa,
                success: action.split.success,
                product: action.split.product,
                developers: action.split.developers
            };
        case 'LOAD_CANDIDATES':
            let candidates = action.candidates.sort((a,b) => a.department.localeCompare(b.department));
            return {
                ...state,
                allCandidates: candidates,
                filteredCandidates: candidates
            };
        case 'FILTER_EMPLOYEES':
            return {
                ...state,
                filteredEmployees: action.filteredEmployees,
            };
        case 'FILTER_CANDIDATES':
            return {
                ...state,
                filteredCandidates: action.filteredCandidates,
            };
        case 'AGENT_INFO':
            email = action.info.email;
            return {
                ...state,
                agentInfo: action.info,
            };
        case 'LOAD_PAGE':
            return {
                ...state,
                loadPage: true,
            };
        case 'IS_ADMIN':
            return {
                ...state,
                isAdmin: true,
            };
        case 'OPEN_MODAL':
            return {
                ...state,
                modalIsOpen: true,
            };
        case 'CLOSE_MODAL':
            return {
                ...state,
                modalIsOpen: false,
            };
        case 'OPEN_MINUS_MODAL':
            return {
                ...state,
                minusModalIsOpen: true,
            };
        case 'CLOSE_MINUS_MODAL':
            return {
                ...state,
                minusModalIsOpen: false,
            };
        case 'FIRST_FORM':
            return {
                ...state,
                firstForm: action.info,
            };
        case 'SAVE_DEPARTMENT':
            return {
                ...state,
                department: action.department,
            };
        case 'LOAD_GROUPS':
            return {
                ...state,
                inventoryGroups: action.inventoryGroups
            };
        case 'ADD_GROUP':
            let groupsStateCopy = {...state};
            groupsStateCopy.allInventory[action.group] = [];
            return {
                ...groupsStateCopy
            };
        case 'SAVE_SELECTION':
            return {
                ...state,
                selectedGroup: action.selectedGroup,
            };
        case 'ADD_ASSIGNED_INVENTORY':
            let copyAssigned = {...state};
            let adjustedInventory = copyAssigned.allEmployees.map(employee => {
                if(employee.workEmail === action.info.email) {
                    if(action.info.type === "inventory") {
                        if(employee.assigned) {
                            employee.assigned = [...employee.assigned,action.info.data];
                        } else {
                            employee.assigned = [action.info.data];
                        }

                    } else {
                        if(employee.assignedAccounts) {
                            employee.assignedAccounts = [...employee.assignedAccounts,action.info.data];
                        } else {
                            employee.assignedAccounts = [action.info.data];
                        }

                    }
                    return employee;
                } else {
                    return employee;
                }
            });
            copyAssigned.allEmployees = [...adjustedInventory];

            return {
               ...copyAssigned
            };
        case 'REMOVE_ASSIGNED_INVENTORY':
            let removeAssigned = {...state};
            let removedInventory = state.allEmployees.map(employee => {
                if(employee.workEmail === action.info.email) {
                    if(action.info.type === "inventory") {
                        let removedArr = [];
                        employee.assigned.map(inventory => inventory.timeframe !== action.info.timeframe ? removedArr.push(inventory): null);
                        employee.assigned = [...removedArr];
                    } else {
                        let removeAccountArr = [];
                        employee.assignedAccounts.map(account => account.timeframe !== action.info.timeframe ? removeAccountArr.push(account): null);
                        employee.assignedAccounts = [...removeAccountArr];
                    }
                    return employee;
                } else {
                    return employee;
                }
            });
            removeAssigned.allEmployees = [...removedInventory];
            return {
                ...removeAssigned,
            };

        case 'EDIT_ASSIGNED_INVENTORY':
            let editAssigned = {...state};
            let editInventory = state.allEmployees.map(employee => {
                if(employee.workEmail === action.info.email) {
                    if(action.info.type === "inventory") {
                        let editedArr = [];
                        employee.assigned.map(inventory => {
                            inventory.timeframe && inventory.timeframe !== action.info.timeframe ? editedArr.push(inventory) : editedArr.push({...action.info.newField});
                        });
                        employee.assigned = [...editedArr];
                    } else {
                        let editedAccountArr = [];
                        employee.assignedAccounts.map(account => {
                            return account.timeframe && account.timeframe !== action.info.timeframe ? editedAccountArr.push(account): editedAccountArr.push(action.info.newField);
                        });
                        employee.assignedAccounts = [...editedAccountArr];
                    }
                    return employee;
                } else {
                    return employee;
                }
            });
            editAssigned.allEmployees = [...editInventory];
            return {
                ...editAssigned,
            };

        default:
            return state;
    }
};
