import React, {Component} from 'react';
import {connect} from 'react-redux';
import SelectItem from "./SelectedItem";
import db, { firebase } from '../../DB/db';
import {loadFirstGroup, saveSelection, updateInventory} from "../../actions/actions";
import {loadSelectedAccount, saveAccountSelection, updateAccounts} from "../../actions/accounts";



class AllTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    onTabClick(e) {
        let group = e.target.dataset.group;
        if(this.props.isInventory) {
            this.props.saveSelection(group);
            this.props.loadFirstGroup(this.props.inventoryList[group]);
        } else {
            this.props.saveAccountSelection(group);
            this.props.loadSelectedAccount(this.props.accounts[group]);
        }
    }

    render() {
        let tabName = this.props.item.name;
        let splitTabName = tabName.split('_').join(' ');
        splitTabName = splitTabName.substring(0,1).toUpperCase() + splitTabName.substring(1);
        let style = this.props.selectedGroup === tabName ? {background : 'rgba(255,255,255,.8)', color: 'RGBA(62, 74, 89, 1.00)', fontWeight: "bold"} : {};
        return (
            <div data-group={tabName.toLowerCase()} onClick={e => this.onTabClick(e)} style={style}>
                <img data-group={tabName.toLowerCase()} alt="" src={this.props.item.icon} style={{height: "24px", width: "auto"}} onClick={e => this.onTabClick(e)}/>
                {splitTabName}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.inventory,
    activeList: state.inventory.activeList,
    selectedGroup: state.store.selectedGroup,
    accounts: state.accounts,
    activeAccountsList: state.accounts.activeList,
    selectedAccount: state.accounts.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
    updateInventory: (itemInfo) => dispatch(updateInventory(itemInfo)),
    saveSelection: (selectedGroup) => dispatch(saveSelection(selectedGroup)),
    loadFirstGroup: (groupList) => dispatch(loadFirstGroup(groupList)),
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo)),
    saveAccountSelection: (selectedGroup) => dispatch(saveAccountSelection(selectedGroup)),
    loadSelectedAccount: (groupList) => dispatch(loadSelectedAccount(groupList))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTabs);
