import React, {Component} from 'react';
import {connect} from 'react-redux';
import db, { firebase } from '../../DB/db';
import { loadAccounts, updateAccounts, loadAccountsGroups, loadSelectedAccount, addAccount, saveAccountSelection } from "../../actions/accounts";



class AllAccountsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    onTabClick(e) {
        this.props.saveAccountSelection(e.target.dataset.group);
        this.props.loadSelectedAccount(this.props.accounts[e.target.dataset.group]);
    }

    render() {
        let tabName = this.props.item.name;
        let splitTabName = tabName.split('_').join(' ');
        splitTabName = splitTabName.substring(0,1).toUpperCase() + splitTabName.substring(1);
        let style = {};
        if(this.props.selectedAccount) {
             style = this.props.selectedAccount === tabName ? {background : 'white', color: 'grey'} : {};
        }
        return (
            <div data-group={tabName.toLowerCase()} onClick={e => this.onTabClick(e)} style={style}>
                <img data-group={tabName.toLowerCase()} alt="" src={this.props.item.icon} style={{height: "24px", width: "auto"}} onClick={e => this.onTabClick(e)}/>
                {splitTabName}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.inventory,
    accounts: state.accounts,
    activeList: state.accounts.activeList,
    selectedAccount: state.accounts.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo)),
    saveAccountSelection: (selectedGroup) => dispatch(saveAccountSelection(selectedGroup)),
    loadSelectedAccount: (groupList) => dispatch(loadSelectedAccount(groupList))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllAccountsTabs);
