import React, {Component} from 'react';
import {connect} from 'react-redux';
import AllAccountsTabs from './AllAccountsTabs';
import db, { firebase } from '../../DB/db'
import { loadAccounts, updateAccounts, loadAccountsGroups, loadSelectedAccount, addAccount, saveAccountSelection } from "../../actions/accounts";
import SelectedAccount from "./SelectedAccount";




class Accounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputIsOpen: false
        };
    }

    componentDidMount() {
        let groupsRef = db.doc('accountGroups/array');
        groupsRef.get()
            .then((data, i) => {
                let allGroupsArray = data.data().all;
                this.props.loadAccountsGroups(allGroupsArray);
                allGroupsArray.map((group, i) => {
                    let collRef = db.collection(`accountGroups/list/${group}`);
                if(i === 0) {
                    this.props.saveAccountSelection(group);
                }
                    let groupInfo = {};
                    groupInfo.groupItems = [];
                    groupInfo.name = group;
                    collRef.get()
                        .then(querySnapshot => {
                            querySnapshot.forEach(data =>  groupInfo.groupItems = [...groupInfo.groupItems, data.data()])
                        })
                        .then(() => this.props.loadAccounts(groupInfo))
                        .then(() => i === 0 ? this.props.loadSelectedAccount(groupInfo.groupItems) : null)
                        .catch(err => console.error(err));
                    return true;
                })
            })
            .catch(err => console.error(err));
    }

    openInputField() {
        if(!this.state.inputIsOpen) {
            this.setState({inputIsOpen: true})
        } else {
            this.setState({inputIsOpen: false})
        }
    }

    addTab (e) {
        let inputFieldValue = e.target.value;
        if(inputFieldValue !== '' && e.which === 13) {
            let groupSplit = inputFieldValue.split(' ').join('_').toLowerCase();
            this.setState({inputIsOpen: false});
            e.target.value = '';
            this.props.saveAccountSelection(groupSplit);
            this.props.loadSelectedAccount([]);
            let allGroupsArray = this.props.accountGroups;
            allGroupsArray.push(groupSplit);
            this.props.loadAccountsGroups(allGroupsArray);
            this.props.addAccount(groupSplit);
            let groupsRef = db.doc('accountGroups/array');
            groupsRef.set({all: allGroupsArray})
                .then(() => console.log('New tab has been saved to the DB'))
                .catch(err => console.error(err));
        }
    }



    render() {
        let inputStyle =  this && this.state && !this.state.inputIsOpen ? {display: 'none'} : {display: 'flex'};
        let plusMinus = this.state.inputIsOpen ? '-' : '+';
        let tabs;
        if(this.props.accountGroups) {
            tabs = this.props.accountGroups.map((item, i) => <AllAccountsTabs item={item} key={i} />)
        }
        return (
            <div className="accounts-container">
               <div className="accounts-header">Accounts</div>
                <div className="accounts-list">
                    <div className="accounts-tabs">
                        <div className="accounts-tabs-database">
                            {tabs}
                        </div>
                        <div className="accounts-plus-tab">
                            <input className="input-accounts-new-tab"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}/>
                            <span onClick={() => this.openInputField()}>{plusMinus}</span>
                        </div>
                    </div>
                    <SelectedAccount />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accountGroups: state.accounts.accountGroups,
    inventory: state.inventory,
    accounts: state.accounts,
});

const mapDispatchToProps = (dispatch) => ({
    loadAccounts: (inventoryList) => dispatch(loadAccounts(inventoryList)),
    saveAccountSelection: (selectedGroup) => dispatch(saveAccountSelection(selectedGroup)),
    loadAccountsGroups: (allGroupsArray) => dispatch(loadAccountsGroups(allGroupsArray)),
    addAccount: (group) => dispatch(addAccount(group)),
    loadSelectedAccount: (groupList) => dispatch(loadSelectedAccount(groupList)),
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
