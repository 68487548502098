import React, {Component} from 'react';
import {connect} from 'react-redux';
import  uuidv4 from 'uuid/v4';
import * as moment from 'moment/moment';
import db from '../../DB/db';
import Settings from "../Settings";
import Employees from "./Employees";
import Candidates from "../candidates/Candidates";
import Inventory from "../inventory/Inventory";
import Accounts from "../accounts/Accounts"
import {loadEmployees, loadCandidates, loadDepartments, loadInventorySettings, assignedToInventory} from "../../actions/actions"
import {loadAccountSettings, updateAccountSettings} from "../../actions/accounts";


class EmployeesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEmployees: true
        };
    }

    splitByDepartment(array) {
        let all = {};
        all.sales = [];
        all.marketing = [];
        all.product = [];
        all.success = [];
        all.developers = [];
        all.qa = [];
        let switchDepartment = (department, employee) => {
            switch(department) {
                case 'sales':
                    all.sales = [...all.sales,employee];
                    break;
                case 'marketing':
                    all.marketing = [...all.marketing,employee];
                    break;
                case 'product':
                    all.product = [...all.product,employee];
                    break;
                case 'success':
                    all.success = [...all.success,employee];
                    break;
                case 'developers':
                    all.developers = [...all.developers,employee];
                    break;
                case 'qa':
                    all.qa = [...all.qa,employee];
                    break;
                default:
                    return null;
            }
        }
        array.map(employee => switchDepartment(employee.department,employee));
        return all;
    }




    componentDidMount() {
        let employeeRef = db.collection(`employees`);
        let employeesArr = [];
        let newArray = [];
             employeeRef.get()
                .then(querySnapshot => {
                    return querySnapshot.forEach(employee => employeesArr = [...employeesArr , employee.data()]);
                })
                 .then( async () => {
                     await employeesArr.map(async employee => {
                         let inventoryAssigned = [];
                         let accountsAssigned = [];
                         let newEmployee = {...employee};
                         employeeRef.doc(employee.workEmail).collection("inventoryAssigned").get().then(querySnapshot => {
                             querySnapshot.forEach(async data => {
                                 inventoryAssigned = [...inventoryAssigned, data.data()]
                             });
                             newEmployee.assigned = inventoryAssigned;
                             this.props.assignedToInventory({email:employee.workEmail, data:inventoryAssigned})
                         });
                         employeeRef.doc(employee.workEmail).collection("accountsAssigned").get().then(querySnapshot => {
                             querySnapshot.forEach(async data => {
                                 accountsAssigned = [...accountsAssigned, data.data()]
                             });
                             newEmployee.assignedAccounts = accountsAssigned;
                         });
                         newArray = [...newArray, newEmployee];
                        return await inventoryAssigned;
                     });
                 })
                .then(() => this.props.loadEmployees(newArray))
                .then(() => this.props.loadDepartments(this.splitByDepartment(employeesArr)))
                .catch(err => console.error(err));

            let candidatesArr = [];
            let candidatesRef = db.collection(`candidates`);
            candidatesRef.get()
                .then(querySnapshot => {
                    return querySnapshot.forEach(candidate => candidatesArr = [...candidatesArr , candidate.data()]);
                })
                .then(() => this.props.loadCandidates(candidatesArr))
                .then(() => this.splitByDepartment(candidatesArr))
                .catch(err => console.error(err));

            let docRef = db.doc("inventoryGroups/settings");
            docRef.get().then(data => this.props.loadInventorySettings(data.data().inputFields)).catch(err => console.log(err));
            let accountRef = db.doc("accountGroups/settings");
            accountRef.get().then(data => this.props.loadAccountSettings(data.data().inputFields)).catch(err => console.log(err));
        }

        render() {
            let isMobile = window.innerWidth < 600;
            return (
                <div className="admin-employees-list">
                    {this.state.openEmployees && <Employees />}
                    {(this.props.isAdmin && !isMobile) && <Inventory />}
                    {this.props.settingsModal && <Settings />}
                </div>
            );
        }
    }

    const mapStateToProps = (state) => ({
    settingsModal: state.inventory.settingsIsOpen,
    isAdmin: state.store.isAdmin,
});

    const mapDispatchToProps = (dispatch) => ({
    loadEmployees: (employees) => dispatch(loadEmployees(employees)),
    loadCandidates: (candidates) => dispatch(loadCandidates(candidates)),
    loadDepartments: (split) => dispatch(loadDepartments(split)),
    loadInventorySettings: (settings) => dispatch(loadInventorySettings(settings)),
    loadAccountSettings: (settings) => dispatch(loadAccountSettings(settings)),
    updateAccountSettings: (newField) => dispatch(updateAccountSettings(newField)),
    assignedToInventory: (assignedToInfo) => dispatch(assignedToInventory(assignedToInfo))
});

    export default connect(mapStateToProps, mapDispatchToProps)(EmployeesList);
