import React, {Component} from 'react';
import {connect} from 'react-redux';
import uuid4 from 'uuid4';
import uniqueId from 'lodash.uniqueid';
import db, { firebase } from '../../DB/db';
import {
    updateInventory,
    editInventoryField,
    removeAssignedToInventory,
    editAssignedToInventory
} from "../../actions/actions";


class SelectedItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            assignedto: ''
        };
    }



    componentDidMount() {
        let itemInfo = this.props.item;
        let onlyWithDisplay = [];
        this.props.settingsFields.map(field => {
            if(field.display) {
                return onlyWithDisplay = [...onlyWithDisplay,field.label.toLowerCase()];
            }
        });
        onlyWithDisplay.map((key) => {
            this.setState({[key]:itemInfo[key]});
        });
        if(itemInfo.timeframe) {
            this.setState({timeframe:itemInfo.timeframe});
        }
    }

    handleInputChange(e) {
        this.setState({[e.target.name]:e.target.value});
    }

    handleSelectChange(e) {
        this.setState({assignedto: e.target.value, edit: true});
    }


    handleEdit() {
        if(this.state.editing) {
            let allInputs = document.querySelectorAll('.selected-item > input');
            let newField = {};
            allInputs.forEach(input => {
                newField[input.name] = input.value;
            });
            if(document.querySelector('.selected-item > select')) {
                newField.assignedto = document.querySelector('.selected-item > select').value;
            }
            newField.timeframe = this.props.item.timeframe;
            let inventoryGroup = this.props.inventory[this.props.selectedGroup];
            inventoryGroup.splice(this.props.index, 1, newField);
            let updatedGroupInfo = {selected:this.props.selectedGroup, editedArray: inventoryGroup};
            this.props.editInventoryField(updatedGroupInfo);
            let serial = this.state.serial ?  this.state.serial : '';
            let searchPath = serial !== '' ? serial : this.state[Object.keys(this.state)[0]];
            let queryPath = serial !== '' ?  "serial" : Object.keys(this.state)[0];
            let validPath = queryPath === "editing" ? Object.keys(this.state)[1] : queryPath;
            if(this.state.timeframe) {
                validPath = "timeframe";
                searchPath = this.state.timeframe;
            };
             newField.group = this.props.selectedGroup;
            let inventoryRef = db.collection(`employees/${this.props.item.email}/inventoryAssigned`).where("timeframe", "==", this.props.item.timeframe);
            inventoryRef.get().then(queryData => queryData.forEach(data => {
                db.collection(`employees/${this.props.item.email}/inventoryAssigned`).doc(data.id)
                    .set({...newField})
                    .then(() => console.log('data has been deleted'))
                    .catch(err => console.error(err));
            }));

            let info = {email:this.props.item.email, timeframe: this.props.item.timeframe, type:'inventory', newField};
            this.props.editAssignedToInventory(info);


            let colRef = db.collection(`inventoryGroups/list/${this.props.selectedGroup}`)
                .where(validPath, "==", searchPath);
            colRef.get().then(data => {
                data.forEach(data => {
                    let docRef = db.collection(`inventoryGroups/list/${this.props.selectedGroup}`).doc(data.id);
                    return docRef.update({
                        ...newField
                    });
                })
            });
        }
        this.setState({editing: !this.state.editing});
    }

    handleDelete() {
        let inventoryGroup = this.props.inventory[this.props.selectedGroup];
        inventoryGroup.splice(this.props.index, 1);
        let updatedGroupInfo = {selected:this.props.selectedGroup, editedArray: inventoryGroup};
        this.props.editInventoryField(updatedGroupInfo);
        let serial = this.props.item.serial ?  this.props.item.serial : '';
        let searchPath = serial !== '' ? serial : this.state[Object.keys(this.props.item)[0]];
        let queryPath = serial !== '' ?  "serial" : Object.keys(this.props.item)[0];
        let validPath = queryPath === "editing" ? Object.keys(this.props.item)[1] : queryPath;
        let colRef = db.collection(`inventoryGroups/list/${this.props.selectedGroup}`)
            .where(validPath, "==", searchPath);
        colRef.get().then(data => {
            data.forEach(data => {
                let docRef = db.collection(`inventoryGroups/list/${this.props.selectedGroup}`).doc(data.id);
                return docRef.delete().then(() => console.log("doc deleted")).catch(err => console.log(err));
            })
        });
        let inventoryRef = db.collection(`employees/${this.props.item.email}/inventoryAssigned`).where("timeframe", "==", this.props.item.timeframe);
           inventoryRef.get().then(queryData => queryData.forEach(data => {
               db.collection(`employees/${this.props.item.email}/inventoryAssigned`).doc(data.id).delete()
                   .then(() => console.log('data has been deleted'))
                   .catch(err => console.error(err));
           }));

           let info = {email:this.props.item.email, timeframe: this.props.item.timeframe, type: 'inventory'};
           this.props.removeAssignedToInventory(info);
    }

    render() {
        let editIcon = require("../../assets/edit.svg");
        let deleteIcon = require("../../assets/delete.svg");
        let iconStyle = {height: "20px", width:"auto", marginRight: "10px"};
        let itemInfo = this.props.item;
        let allItems;
        let editItems;
        let onlyWithDisplay = [];
        let assignedOptions;
        if(this.props.allEmployees) {
            assignedOptions =  this.props.allEmployees.map((employee,i) => (<option data-email={employee.workEmail} key={i}>{employee.firstName} {employee.lastName}</option>))
        }
        this.props.settingsFields.map(field => {
            if(field.display) {
                return onlyWithDisplay = [...onlyWithDisplay,field.label.toLowerCase()];
            }
        });
        allItems = onlyWithDisplay.map((key,i) => {
                return (<span key={i} className={key}>{itemInfo[key]}</span>);
        });

        editItems = onlyWithDisplay.map((key,i) => {
            let style = onlyWithDisplay.length === i + 1 ? { borderRadius: "0 2px 2px 0", marginRight: "10px"} : {};
            let returnField;
            let keyNoSpace = key.replace(' ', '').toLowerCase();
            if(keyNoSpace === 'assignedto') {
                returnField = (<select
                    name="assignedto"
                    key={i}
                    onChange={(e) => this.handleSelectChange(e)}
                    value={this.state.assignedto ? this.state.assignedto : ''}
                    style={style}>{assignedOptions}</select>)
            } else {
                returnField = (<input name={keyNoSpace} value={this.state[keyNoSpace] ? this.state[keyNoSpace] : ''} key={i} onChange={e => this.handleInputChange(e)} style={style}/>)
            }
            return returnField;
        });

        return (
            <div className="selected-item">
                {!this.state.editing ? allItems : editItems}
                <img className="iconsEditDelete" alt="" src={editIcon} style={iconStyle} onClick={(e) => this.handleEdit(e)}/>
                <img className="iconsEditDelete" alt="" src={deleteIcon} style={iconStyle} onClick={(e) => this.handleDelete(e)}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.store.allInventory,
    inventory: state.inventory,
    selectedGroup: state.store.selectedGroup,
    settingsFields: state.inventory.updatedSettings,
    settingsAccount: state.accounts.updatedSettings,
    allEmployees: state.store.allEmployees
});

const mapDispatchToProps = (dispatch) => ({
    updateInventory: (itemInfo) => dispatch(updateInventory(itemInfo)),
    editInventoryField: (updatedGroupInfo) => dispatch(editInventoryField(updatedGroupInfo)),
    removeAssignedToInventory: (info) => dispatch(removeAssignedToInventory(info)),
    editAssignedToInventory: (info) => dispatch(editAssignedToInventory(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedItem);
