import React, {Component} from 'react';
import {connect} from 'react-redux';
import {filterEmployees} from "../actions/actions";



class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    findMatches(wordToMatch, employees, key) {
        if(key !== 220) {
            return employees.filter(employee => {
                // here we need to figure out if the city or state matches what was searched
                let wordToMatchSmall = wordToMatch.toLowerCase();
                const regex = new RegExp(wordToMatchSmall, 'gi');
                let employeeFirstName = employee.firstName.toLowerCase();
                let employeeLastName = employee.firstName.toLowerCase();
                let emailSmall = employee.workEmail.toLowerCase();
                let office = employee.officeLocation.toLowerCase();
                let deviceid = '';
                let serial = '';
                let department = employee.department.toLowerCase();
                if (employee.assigned) {
                    employee.assigned.map(device => {
                        if (device.deviceid.toLowerCase().match(regex)) {
                            deviceid = device.deviceid.toLowerCase();
                        }
                        if (device.serial.toLowerCase().match(regex)) {
                            serial = device.serial.toLowerCase();
                        }
                        return null;
                    });
                }
                return employeeFirstName.match(regex) ||  employeeLastName.match(regex) || emailSmall.match(regex) || office.match(regex) || deviceid.match(regex) || serial.match(regex) || department.match(regex)
            });
        }
    };

    runSearch(e) {
        let employees = this.props.allEmployees;
        let value = e.target.value;
        let key = e.target.which;
        if(value === '') {
            this.props.filterEmployees(employees);
        } else {
            let matched = this.findMatches(value, employees, key);
            this.props.filterEmployees(matched);
        }


    }

    render() {
        return (
            <div className="search-container">
                <input name="search-field" placeholder="Search" onKeyUp={e => this.runSearch(e)}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    allEmployees: state.store.allEmployees,
    employeesFiltered: state.store.filteredEmployees,
});

const mapDispatchToProps = (dispatch) => ({
    filterEmployees: (filteredEmployees) => dispatch(filterEmployees(filteredEmployees)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
