import React from 'react';
import AdminPage from './components/AdminPage'
import './App.scss';

function App() {
  return (
    <div className="App">
      <AdminPage />
    </div>
  );
}

export default App;
