import React, {Component} from 'react';
import {connect} from 'react-redux';
import Sortable from 'react-sortablejs';
import SettingsTabs from './settingTabs';
import SettingsFileds from "./SettingsFields"
import db, { firebase } from '../DB/db';
import {
    addGroup,
    closeSettings, loadFirstGroup,
    loadInventoryGroups,
    loadInventorySettings,
    updateInventorySettings
} from "../actions/actions";
import {
    loadAccounts,
    loadAccountSettings,
    loadAccountsGroups,
    saveAccountSelection,
    updateAccountSettings
} from "../actions/accounts";
import AllTabs from "./inventory/AllTabs";
import uuid4 from "uuid4";
import AllAccountsTabs from "./accounts/AllAccountsTabs";




class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    closeSettings () {
        this.props.closeSettings();
    }


    addInputField() {
        let label = document.querySelector("#labelInputSettings").value;
        let placeholder = document.querySelector("#placeholderInputSettings").value;
        let newField = {};
        newField.label = label;
        newField.placeholder = placeholder;
        if(this.props.isInventory) {
            let allField =  this.props.settingsFields || [];
            allField.push(newField);
            this.props.updateInventorySettings(newField);
            let docRef = db.doc("inventoryGroups/settings");
            docRef
                .set({inputFields: allField})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        } else {
            let allField = this.props.settingsAccount || [];
            allField.push(newField);
            this.props.updateAccountSettings(newField);
            let docRef = db.doc("accountGroups/settings");
            docRef
                .set({inputFields: allField})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        }

    }

    handleDrop(order, sortable, evt) {
        let newSettings = this.props.isInventory ? this.props.settingsFields : this.props.settingsAccount;
        let oldItem = newSettings[evt.oldIndex];
        newSettings.splice(evt.oldIndex,1);
        newSettings.splice(evt.newIndex,0,oldItem);
        let settings = newSettings.map((field,i) => {
            field.order = i;
            return field;
        });
        if(this.props.isInventory) {
            this.props.loadInventorySettings(settings);
            let docRef = db.doc("inventoryGroups/settings");
            docRef
                .set({inputFields: settings})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        } else {
            this.props.loadAccountSettings(settings);
            let docRef = db.doc("accountGroups/settings");
            docRef
                .set({inputFields: settings})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        }
    }

    handleDropTabs(order, sortable, evt) {
        let newGroups = this.props.isInventory ? this.props.inventoryGroups : this.props.accountGroups;
        let oldItem = newGroups[evt.oldIndex];
        newGroups.splice(evt.oldIndex,1);
        newGroups.splice(evt.newIndex,0,oldItem);
        let settings = newGroups.map((field,i) => {
            field.order = i;
            return field;
        });
        if(this.props.isInventory) {
            this.props.loadInventoryGroups(settings);
            let docRef = db.doc("inventoryGroups/array");
            docRef
                .set({all: settings})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        } else {
            this.props.loadAccountsGroups(settings);
            let docRef = db.doc("accountGroups/array");
            docRef
                .set({all: settings})
                .then(() => console.log(''))
                .catch(err => console.error(err));
        }
    }


    render() {
        let tabs;
        if(this.props.inventoryGroups) {
            tabs = this.props.inventoryGroups.map((item,i) => {
                return (<SettingsTabs data-group={item.name.toLowerCase()} key={uuid4()} index={uuid4()} item={item}/>)
            })
        }
        let accountTabs;
        if(this.props.accountGroups) {
            accountTabs = this.props.accountGroups.map(item => {
                return (<SettingsTabs data-group={item.name.toLowerCase()} key={uuid4()} index={uuid4()} item={item}/>)
            })
        }

        let settingField;
        if(this.props.settingsFields && this.props.isInventory) {
            settingField = this.props.settingsFields.map((field,i) => {
                return  (<SettingsFileds key={i} index={i} field={field} draggable id={field} data-id={i + 1}/>);
            })
        }
        let accountSettings;
        if(this.props.settingsAccount && !this.props.isInventory) {
            accountSettings = this.props.settingsAccount.map((field,i) => {
                return  (<SettingsFileds key={i} data-id={i} index={i} field={field} id={field} draggable/>);
            })
        }

        return (
            <div className="settings-modal-page">
                <div className="settings-modal-container">
                        <Sortable className="inventory-tabs-settings" onChange={(order, sortable, evt) => this.handleDropTabs(order, sortable, evt)}>
                        {this.props.isInventory ? tabs : accountTabs} </Sortable>
                    <div className="cancelSign" onClick={() => this.closeSettings()}>X</div>
                    <div className="settings-fields-container">
                        <Sortable onChange={(order, sortable, evt) => this.handleDrop(order, sortable, evt)}
                        >{this.props.isInventory ? settingField : accountSettings}</Sortable>
                        <form className="settings-fields-inputs">
                            <span/>
                            <input id="labelInputSettings" name="label" placeholder="Field Label"/>
                            <input id="placeholderInputSettings" name="placeholder" placeholder="Placeholder text"/>
                            <span className="centered" onClick={() => this.addInputField()}>Add</span>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isInventory: state.inventory.isInventory,
    settingsFields: state.inventory.updatedSettings,
    settingsAccount: state.accounts.updatedSettings,
    inventoryGroups: state.store.inventoryGroups,
    inventory: state.inventory,
    accountGroups: state.accounts.accountGroups,
    accounts: state.accounts,
});

const mapDispatchToProps = (dispatch) => ({
    closeSettings: () => dispatch(closeSettings()),
    loadInventorySettings: (settings) => dispatch(loadInventorySettings(settings)),
    updateInventorySettings: (newField) => dispatch(updateInventorySettings(newField)),
    loadAccountSettings: (settings) => dispatch(loadAccountSettings(settings)),
    updateAccountSettings: (newField) => dispatch(updateAccountSettings(newField)),
    loadInventoryGroups: (allGroupsArray) => dispatch(loadInventoryGroups(allGroupsArray)),
    loadAccountsGroups: (allGroupsArray) => dispatch(loadAccountsGroups(allGroupsArray)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
