import React, {Component} from 'react';
import {connect} from 'react-redux';
import db, { firebase } from '../DB/db';
import { closeSettings, loadInventorySettings } from "../actions/actions";
import {loadAccountSettings, updateAccountSettings} from "../actions/accounts";



class SettingsFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

     changeCheckBox(e) {
         if(this.props.isInventory) {
             let newSettings = [];
             let settingFields = this.props.settingsFields;
             settingFields.map(field => {
                 let newField = {...field};
                 if (field.label.toLowerCase() === this.props.field.label.toLowerCase()) {
                     newField.display = e.target.checked;
                     newSettings.push(newField);
                     console.log(newSettings);
                     this.props.loadInventorySettings(newSettings);
                 } else {
                     newSettings.push(newField);
                     this.props.loadInventorySettings(newSettings);
                 }
                 return field
             });
             db.doc("inventoryGroups/settings").set({inputFields: newSettings}).then(() => console.log("")).catch(err => console.error(err));
         } else {
             let newSettings = [];
             let settingFields = this.props.settingsAccount;
             settingFields.map(field => {
                 let newField = {...field};
                 if (field.label.toLowerCase() === this.props.field.label.toLowerCase()) {
                     newField.display = e.target.checked;
                     newSettings.push(newField);
                     this.props.loadAccountSettings(newSettings);
                 } else {
                     newSettings.push(newField);
                     this.props.loadAccountSettings(newSettings);
                 }
                 return field
             });
             db.doc("accountGroups/settings").set({inputFields: newSettings}).then(() => console.log("")).catch(err => console.error(err));
         }
    }

    removeField() {
        if(this.props.isInventory) {
            let settingFields = this.props.settingsFields;
            let newSettings = [];
            settingFields.map(field => {
                let newField = {...field};
                if(field.label.toLowerCase() !== this.props.field.label.toLowerCase()) {
                    newSettings.push(newField);
                }
                return field
            });
            this.props.loadInventorySettings(newSettings);
            db.doc("inventoryGroups/settings").set({inputFields:newSettings}).then(() => console.log("")).catch(err => console.error(err));
        } else {
            let settingFields = this.props.settingsAccount;
            let newSettings = [];
            settingFields.map(field => {
                let newField = {...field};
                if(field.label.toLowerCase() !== this.props.field.label.toLowerCase()) {
                    newSettings.push(newField);
                }
                return field
            });
            this.props.loadAccountSettings(newSettings);
            db.doc("accountGroups/settings").set({inputFields:newSettings}).then(() => console.log("")).catch(err => console.error(err));
        }

    }


    render() {
        return (
            <div className="settings-modal-fields">
                <span><input type="checkBox" checked={this.props.field.display} onChange={e => this.changeCheckBox(e)}/></span>
                <span>{this.props.field.label}</span>
                <span>{this.props.field.placeholder}</span>
                <span className="centered" data-field={this.props.field.label} onClick={() => this.removeField()}>X</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isInventory: state.inventory.isInventory,
    settingsFields: state.inventory.settingsFields,
    settingsAccount: state.accounts.updatedSettings
});

const mapDispatchToProps = (dispatch) => ({
    closeSettings: () => dispatch(closeSettings()),
    loadInventorySettings: (settings) => dispatch(loadInventorySettings(settings)),
    loadAccountSettings: (settings) => dispatch(loadAccountSettings(settings)),
    updateAccountSettings: (newField) => dispatch(updateAccountSettings(newField))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsFields);
