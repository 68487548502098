import React, {Component} from 'react';
import {connect} from 'react-redux';
import { storageRef } from "../DB/db";




class InputIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <input style={this.props.style} type="file" id="uploader" onChange={this.uploadFile}/>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(InputIcon);
