import  firebase from 'firebase/app';
import 'firebase/storage'
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/messaging';

let config = {
    apiKey: "AIzaSyACxwk-V7RznR11VBrY-ufOhTbS-6oK37g",
    authDomain: "fa-aria.firebaseapp.com",
    databaseURL: "https://fa-aria.firebaseio.com",
    projectId: "fa-aria",
    storageBucket: "fa-aria.appspot.com",
};

let ariaApp = firebase.initializeApp(config);

let db = ariaApp.firestore();
export let storageRef = firebase.storage();



let provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');


export { firebase, provider };
export default db;
