import React, {Component} from 'react';
import {connect} from 'react-redux';



class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        let logo = require('../assets/FreeAgentLogo-01.png');
        let guadalajaraLogo = require('../assets/guadalajara.png');
        let montanaLog = require('../assets/montana.png');
        let cityStyle = {height: '80px', width: '80px', borderRadius: '50%', marginRight: '5%', marginLeft:'5%'}
        return (
            <div className="admin-footer">
               Footer
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
