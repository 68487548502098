
export const agentInfo = (info) => ({
    type: 'AGENT_INFO',
    info
});

export const loadPage = () => ({
    type: 'LOAD_PAGE'
});

export const isAdminAction = () => ({
    type: 'IS_ADMIN'
});

export const openModal = () => ({
    type: 'OPEN_MODAL'
});

export const closeModal = () => ({
    type: 'CLOSE_MODAL'
});

export const openMinusModal = () => ({
    type: 'OPEN_MINUS_MODAL'
});

export const closeMinusModal = () => ({
    type: 'CLOSE_MINUS_MODAL'
});

export const saveFirstForm = (info) => ({
    type: 'FIRST_FORM',
    info
});

export const saveDepartment = (department) => ({
    type: 'SAVE_DEPARTMENT',
    department
});

export const loadEmployees = (employees) => ({
    type: 'LOAD_EMPLOYEES',
    employees
});

export const loadDepartments = (split) => ({
    type: 'ALL_DEPARTMENTS',
    split
});

export const loadCandidates = (candidates) => ({
    type: 'LOAD_CANDIDATES',
    candidates
});

export const filterEmployees = (filteredEmployees) => ({
    type: 'FILTER_EMPLOYEES',
    filteredEmployees
});

export const filterCandidates = (filteredCandidates) => ({
    type: 'FILTER_CANDIDATES',
    filteredCandidates
});

export const removeEmployee = (info) => ({
    type: 'REMOVE_EMPLOYEE',
    info
});

export const loadInventory = (inventoryList) => ({
    type: 'LOAD_INVENTORY',
    inventoryList
});

export const updateInventory = (itemInfo) => ({
    type: 'UPDATE_INVENTORY',
    itemInfo
});



export const loadInventoryGroups = (inventoryGroups) => ({
    type: 'LOAD_GROUPS',
    inventoryGroups
});

export const loadFirstGroup = (groupList) => ({
    type: 'LOAD_FIRST',
    groupList
});



export const addGroup = (group) => ({
    type: 'ADD_GROUP',
    group
});

export const saveSelection = (selectedGroup) => ({
    type: 'SAVE_SELECTION',
    selectedGroup
});

export const openSettings = () => ({
    type: 'OPEN_SETTINGS',
});

export const closeSettings = () => ({
    type: 'CLOSE_SETTINGS',
});

export const slideInventory = () => ({
    type: 'IS_INVENTORY',
});

export const loadInventorySettings = (settings) => ({
    type: 'LOAD_INVENTORY_SETTINGS',
    settings
});

export const updateInventorySettings = (newField) => ({
    type: 'UPDATE_SETTINGS',
    newField
});

export const editInventoryField = (updatedGroupInfo) => ({
    type: 'EDIT_INVENTORY_FIELD',
    updatedGroupInfo
});

export const assignedToInventory = (assignedToInfo) => ({
    type: 'INVENTORY_ASSIGNED',
    assignedToInfo
});

export const editAssignedToInventory = (info) => ({
    type: 'EDIT_ASSIGNED_INVENTORY',
    info
});

export const addAssignedToInventory = (info) => ({
    type: 'ADD_ASSIGNED_INVENTORY',
    info
});


export const removeAssignedToInventory = (info) => ({
type: 'REMOVE_ASSIGNED_INVENTORY',
    info
});





























