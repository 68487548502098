import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as moment from 'moment/moment';
import {
    saveFirstForm,
    closeModal,
    closeMinusModal,
    removeEmployee,
    loadEmployees,
    loadCandidates
} from '../../actions/actions'
import  { getDepartmentColor } from '../utils/utils';
import db from "../../DB/db";

class ResignEmployee extends Component {
    state = {
        formOne: true,
        formTwo: false,
        workEmail: ''
    };

    resignEmployee(e) {
        let email = e.target.dataset.email;
        let employeeToResign = {};
        this.props.employees.map(employees => employees.workEmail === email ? employeeToResign = employees : null);
        let assignedArray = employeeToResign.assigned || [];
        let assignedAccounts = employeeToResign.assignedAccounts || [];
         if (assignedArray.length > 0 || assignedAccounts.length > 0) {
            alert('This Employee still has devices or accounts assigned');
         } else {
             let withoutResigned = [];
             this.props.employees.map(employee => employee.workEmail !== email ? withoutResigned.push(employee) : null);
             this.props.loadEmployees(withoutResigned);
             let onlyDepartment = [];
             withoutResigned.map(employee => employee.department === this.props.department ? onlyDepartment.push(employee)  : null);
             this.props.removeEmployee({department:this.props.department, newArray: onlyDepartment});

             let employeeRef = db.doc(`employees/${email}`);
             employeeRef.collection('inventoryAssigned').get().then(querySnapshot => querySnapshot.forEach(doc => {
                 employeeRef.collection('inventoryAssigned').doc(doc.id).delete().then(() => console.log('')).catch(e => console.error(e));
             }));
             employeeRef.collection('accountsAssigned').get().then(querySnapshot => querySnapshot.forEach(doc => {
                 employeeRef.collection('accountsAssigned').doc(doc.id).delete().then(() => console.log('')).catch(e => console.error(e));
             }));
             employeeRef.delete().then(() => {
                 return db.collection('resignedList')
                     .add({})
                     .then(doc => {
                         db.doc(doc.path)
                             .set({...employeeToResign})
                             .then(() => null)
                             .catch(e => console.error(e))
                     })
             }).catch(e => console.error(e));
         }
    }

    isHired(e) {
        let email = e.target.dataset.email;
        let withoutResigned = [];
        let candidateInfo = {};
        this.props.candidates.map(employee => employee.workEmail !== email ? withoutResigned.push(employee) : candidateInfo = employee);
        let employeeRef = db.doc(`employees/${email}`);
        employeeRef.set({...candidateInfo}).then(() => this.props.loadCandidates(withoutResigned)).catch(e => console.error(e));
        let candidateRef = db.doc(`candidates/${email}`);
        candidateRef.delete().then(() => console.log('doc has been deleted')).catch(e => console.error(e));
        let withNewEmployee = [...this.props.employees,{...candidateInfo}];
        this.props.loadEmployees(withNewEmployee);
        let onlyDepartment = [];
        withNewEmployee.map(employee => employee.department === this.props.department ? onlyDepartment.push(employee)  : null);
        this.props.removeEmployee({department:this.props.department, newArray: onlyDepartment});
    }

    removeCandidate(e) {
        let email = e.target.dataset.email;
        let withoutResigned = [];
        let candidateInfo = {};
        this.props.candidates.map(employee => employee.workEmail !== email ? withoutResigned.push(employee) : candidateInfo = employee);
        let candidateRef = db.doc(`candidates/${email}`);
        candidateRef.delete().then(() => this.props.loadCandidates(withoutResigned)).catch(e => console.error(e));
        let notHiredRef = db.collection(`notHiredCandidates`);
        notHiredRef.add({}).then(doc => {
            db.doc(doc.path).set({...candidateInfo}).then(() => console.log('')).catch(e => console.error(e));
        })
    }

    closeForm() {
        this.props.closeMinusModal();
    }


    render() {
        let color = getDepartmentColor(this.props.department);
        let buttonBackground = {
            backgroundColor: `${color}`,
            boxShadow: `0px 0px 3px 1px ${color}`
        };

         let listToDisplay = this.props.employees;
        let department = this.props.department;
        let filteredByDepartment = [];
        listToDisplay.map(employee => employee.department === department ? filteredByDepartment.push(employee) : null);
        let assignedOptions;
        if(filteredByDepartment) {
            assignedOptions = filteredByDepartment.map((employee, i) => {
                return (<div key={i}>
                    <span>{employee.firstName} {employee.lastName}</span>
                     <span className="resign-btn"
                          data-email={employee.workEmail}
                          style={buttonBackground}
                          onClick={(e) => this.resignEmployee(e)}>Resign</span>
                </div>)
            });
        }
         return (
            <div className="modal-container">
               <div className="modal-form">
                   <div className="modal-employees-list">{assignedOptions}</div>
               </div>
                <section className="modal-resign-closeButton" onClick={() => this.closeForm()}>Close</section>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAdmin: state.store.isAdmin,
    department : state.store.department,
    employees: state.store.allEmployees,
    candidates: state.store.allCandidates
});

const mapDispatchToProps = (dispatch) => ({
    loadEmployees: (employees) => dispatch(loadEmployees(employees)),
    saveFirstForm: (info) => dispatch(saveFirstForm(info)),
    closeMinusModal: () => dispatch(closeMinusModal()),
    removeEmployee: (info) => dispatch(removeEmployee(info)),
    loadCandidates: (candidates) => dispatch(loadCandidates(candidates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResignEmployee);
