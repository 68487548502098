import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import invociesReducer from './reducers/adminReducer';
import accountsReducer from './reducers/accountsReducer';
import inventoryReducer from "./reducers/inventoryReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose;

export default () => {
    return createStore(
        combineReducers({
            store: invociesReducer,
            inventory: inventoryReducer,
            accounts: accountsReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
};
