import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import db, { firebase } from '../DB/db';
import { startLoginRedirect  } from './auth';
import { loadPage, agentInfo, isAdminAction } from '../actions/actions';
import Header from './Header';
import EmployeesList from "./employees/EmployeesList";
import NewEmployee from "./employees/NewEmployee";
import ResignEmployee from "./employees/ResignEmployee"
import StatesFlages from "./Footer";
import Search from './Search';
import Employees from "./employees/Employees";
import { OAuth2Client } from 'google-auth-library';


class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openEmployees: true,
            testMode: false
        };
    }

    componentWillMount() {
        if (!this.props.load) {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    let displayName = user.displayName;
                    let email = user.email;
                    let emailVerified = user.emailVerified;
                    let photoURL = user.photoURL;
                    let uid = user.uid;
                    let phoneNumber = user.phoneNumber;
                    let providerData = user.providerData;
                    user.getIdToken().then(accessToken => {
                        let agent = {
                            displayName: displayName,
                            email: email,
                            emailVerified: emailVerified,
                            phoneNumber: phoneNumber,
                            photoURL: photoURL,
                            uid: uid,
                            accessToken: accessToken,
                            providerData: providerData
                        };
                        this.props.agentInfo(agent);
                        if(email.split('@')[1] === 'freeagentsoftware.com') {
                            this.props.loadPage();
                        } else {
                            alert('Only employees Of FreeAgent can access this service')
                        }
                    }).catch(err => console.error(err));
                } else {
                    this.props.startLoginRedirect();
                }
            })
        }
    }

    deleteEmail() {
    /*    const SCOPES = ['https://www.googleapis.com/auth/admin.directory.user'];
      db.doc('token/apiKey').get().then(key => {
          let CONFIG_CLIENT_ID = key.data().client_id;
          let CONFIG_CLIENT_SECRET = key.data().client_secret;
          let FUNCTIONS_REDIRECT = key.data().redirects;
          const functionsOauthClient = new OAuth2Client(CONFIG_CLIENT_ID, CONFIG_CLIENT_SECRET,
              FUNCTIONS_REDIRECT);
          return window.location.href = functionsOauthClient.generateAuthUrl({
              access_type: 'offline',
              scope: SCOPES,
              prompt: 'consent',
          });
      });*/

       axios.get('https://fa-aria.firebaseapp.com/oauthcallback')
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    componentDidMount() {
        let adminList = [];
        let collRef = db.collection('adminList');
        collRef.get()
            .then(querySnapshot => {
                querySnapshot.forEach(data => adminList = [...adminList, data.data().email])
            })
            .then(() => adminList.includes(this.props.agent.email) ? this.props.isAdminAction() : console.log(this.props.agentInfo.email,adminList, 'user is not admin'))
            .catch(err => console.error(err));
    };



    render() {
        let load = this.props.load;
        let modalIsOpen = this.props.modalIsOpen;
        let minusModalIsOpen = this.props.minusModalIsOpen;
        return (
            <div className="adminPage-container">
                {load && <Header />}
                {load && <Search />}
                {load && <EmployeesList />}
                {modalIsOpen && <NewEmployee />}
                {minusModalIsOpen && <ResignEmployee />}
                {this.state.testMode && <div onClick={(e) => this.deleteEmail(e)} style={{color:"white", fontSize: "26px"}}>Test</div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    load: state.store.loadPage,
    agent: state.store.agentInfo,
    modalIsOpen: state.store.modalIsOpen,
    minusModalIsOpen: state.store.minusModalIsOpen
});

const mapDispatchToProps = (dispatch) => ({
    startLoginRedirect: () => dispatch(startLoginRedirect()),
    agentInfo: (info) => dispatch(agentInfo(info)),
    loadPage: () => dispatch(loadPage()),
    isAdminAction: () => dispatch(isAdminAction()),

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
