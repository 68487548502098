import React, {Component} from 'react';
import {connect} from 'react-redux';
import db, { firebase } from '../DB/db';
import {
    addGroup,
    closeSettings, loadFirstGroup, loadInventory,
    loadInventoryGroups,
    loadInventorySettings, saveSelection,
    updateInventorySettings
} from "../actions/actions";
import {
    loadAccounts,
    loadAccountSettings,
    loadAccountsGroups,
    saveAccountSelection,
    updateAccountSettings
} from "../actions/accounts";



class SettingsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
    }

    componentWillMount() {
        let price = this.props.item.price || '';
        let icon = this.props.item.icon || '';
        let name = this.props.item.name || '';
        let admin = this.props.item.admin || '';
        let term = this.props.item.term || '';
        this.setState({price, icon, name, admin, term});
    }

    changeCheckBox(e) {
        if(this.props.isInventory) {
            let newSettings = [];
            let tabSettingFields = this.props.inventoryGroups;
            tabSettingFields.map(field => {
                let newField = {...field};
                if (field.name.toLowerCase() === this.props.item.name.toLowerCase()) {
                    newField.display = e.target.checked;
                    newSettings.push(newField);
                } else {
                    newSettings.push(newField);
                }
                return field
            });
            this.props.loadInventoryGroups(newSettings);
            db.doc("inventoryGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
        } else {
            let newSettings = [];
            let accountTabsSettingFields = this.props.accountGroups;
            accountTabsSettingFields.map(field => {
                let newField = {...field};
                if (field.name.toLowerCase() === this.props.item.name.toLowerCase()) {
                    newField.display = e.target.checked;
                    newSettings.push(newField);
                } else {
                    newSettings.push(newField);
                }
                return field
            });
            this.props.loadAccountsGroups(newSettings);
            db.doc("accountGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
        }
    }

    editFieldsOpen(e) {
        this.setState({edit: !this.state.edit});
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    changeSelect(e) {
        this.setState({term: e.target.value})
    }

    editTab(e) {
        let nameValue = this.state.name;
        let iconValue = this.state.icon;
        let priceValue = this.state.price;
        let adminValue = this.state.admin;
        let termValue = this.state.term;
        if (e.target.value !== '' && e.which === 13) {
            if(this.props.isInventory) {
                let newSettings = [];
                let tabSettingFields = this.props.inventoryGroups;
                tabSettingFields.map(field => {
                    let newField = {...field};
                    if (field.name.toLowerCase() === this.props.item.name.toLowerCase()) {
                        newField.name = nameValue;
                        newField.icon = iconValue;
                        newField.price = priceValue;
                        newField.admin = adminValue;
                        newSettings.push(newField);
                        let oldName = field.name.toLowerCase();
                        let newName = this.state.name.toLowerCase();
                        let groupInfo = {};
                        groupInfo.groupItems = [];
                        groupInfo.name = newName;
                        if(oldName !== newName) {
                            db.collection(`inventoryGroups/list/${oldName}`).get().then(query => {
                                return query.forEach(data => {
                                    groupInfo.groupItems = [...groupInfo.groupItems,data.data()];
                                    return db.collection(`inventoryGroups/list/${newName}`).add({}).then(doc => {
                                        db.doc(doc.path).set(data.data()).then(() => null).catch(err => console.error(err));
                                        return db.collection(`inventoryGroups/list/${oldName}`).doc(data.id).delete();
                                    })
                                });
                            }).then(() => this.props.loadInventory(groupInfo));
                            if(oldName === this.props.selectedGroup) {
                                this.props.saveSelection(newName);
                            }
                        }
                    } else {
                        newSettings.push(newField);
                    }
                    return field
                });
                this.props.loadInventoryGroups(newSettings);
                db.doc("inventoryGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
            } else {
                let newSettings = [];
                let accountTabsSettingFields = this.props.accountGroups;
                accountTabsSettingFields.map(field => {
                    let newField = {...field};
                    if (field.name.toLowerCase() === this.props.item.name.toLowerCase()) {
                        newField.name = nameValue;
                        newField.icon = iconValue;
                        newField.price = priceValue;
                        newField.admin = adminValue;
                        newField.term = termValue;
                        newSettings.push(newField);
                        let oldName = field.name.toLowerCase();
                        let newName = this.state.name.toLowerCase();
                        let groupInfo = {};
                        groupInfo.groupItems = [];
                        groupInfo.name = newName;
                        if(oldName !== newName) {
                            db.collection(`accountGroups/list/${oldName}`).get().then(query => {
                                return query.forEach(data => {
                                    groupInfo.groupItems = [...groupInfo.groupItems,data.data()];
                                    return db.collection(`accountGroups/list/${newName}`).add({}).then(doc => {
                                        db.doc(doc.path).set(data.data()).then(() => null).catch(err => console.error(err));
                                        return db.collection(`accountGroups/list/${oldName}`).doc(data.id).delete();
                                    })
                                });
                            }).then(() => this.props.loadAccounts(groupInfo));
                            if (oldName === this.props.selectedAccount) {
                                this.props.saveAccountSelection(newName);
                            }
                        }
                    } else {
                        newSettings.push(newField);
                    }
                    return field
                });
                this.props.loadAccountsGroups(newSettings);
                db.doc("accountGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
            }
        }
    }

    handleDelete(e) {
        let group = e.target.dataset.group;
        let newSettings = [];
        if(this.props.isInventory) {
            db.collection(`inventoryGroups/list/${group}`).get()
                .then(queryData => {
                    if(queryData.size > 0) {
                        return queryData.forEach(doc => {
                            return db.collection(`inventoryGroups/list/${group}`).doc(doc.id).delete().then(() => null).catch(e => console.error(e));
                        })
                    } else {
                        return true;
                    }
                })
                .then(() => {
                    let tabSettingFields = this.props.inventoryGroups;
                    tabSettingFields.map(field => field.name.toLowerCase() !== group ?  newSettings = [...newSettings,field] : null);
                    this.props.loadInventoryGroups(newSettings);
                    return db.doc("inventoryGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
                })
                .catch(e => console.error(e));
        } else {
            db.collection(`accountGroups/list/${group}`).get()
                .then(queryData => {
                    if(queryData.size > 0) {
                        return queryData.forEach(doc => {
                            return db.collection(`accountGroups/list/${group}`).doc(doc.id).delete().then(() => null).catch(e => console.error(e));
                        })
                    } else {
                        return true;
                    }
                })
                .then(() => {
                    let tabSettingFields = this.props.accountGroups;
                    tabSettingFields.map(field => field.name.toLowerCase() !== group ? newSettings = [...newSettings,field] : null);
                    this.props.loadAccountsGroups(newSettings);
                    return db.doc("accountGroups/array").set({all: newSettings}).then(() => console.log("")).catch(err => console.error(err));
                })
                .catch(e => console.error(e));
        }
    }

    render() {
        let splitTabName = this.props.item.name.split('_').join(' ');
        splitTabName = splitTabName.substring(0,1).toUpperCase() + splitTabName.substring(1);
        return (<section>
                <input type="checkBox" checked={this.props.item.display ? this.props.item.display : false} onChange={e => this.changeCheckBox(e)}/>
                <div data-group={this.props.item.name.toLowerCase()} >
                    <img data-group={this.props.item.name.toLowerCase()} alt="" src={this.props.item.icon} onClick={e => this.editFieldsOpen(e)}/>
                    {!this.state.edit &&<span>{splitTabName}</span>}
                    {this.state.edit && <form>
                        <input name="name"
                               key={this.props.index + '1'}
                               value={this.state.name}
                               onChange={e => this.handleInputChange(e)}
                               onKeyPress={e => this.editTab(e)}
                        />
                        <input name="icon"
                               key={this.props.index + '2'}
                               value={this.state.icon}
                               onChange={e => this.handleInputChange(e)}
                               onKeyPress={e => this.editTab(e)}
                        />
                        <input name="price"
                               key={this.props.index + '3'}
                               value={this.state.price}
                               onChange={e => this.handleInputChange(e)}
                               onKeyPress={e => this.editTab(e)}
                               placeholder="Price"
                        />
                        {!this.props.isInventory && <input name="admin"
                               key={this.props.index + '4'}
                               value={this.state.admin}
                               onChange={e => this.handleInputChange(e)}
                               onKeyPress={e => this.editTab(e)}
                               placeholder="Admin"
                        />}
                        {!this.props.isInventory && <select
                            value={this.state.term}
                            name="term"
                            className="input-new-tab-monthly"
                            onChange={e => this.changeSelect(e)}

                        >
                            <option value="">Select the term</option>
                            <option value="monthly">Monthly</option>
                            <option value="annual">Annual</option>
                            <option value="lifetime">Lifetime</option>
                        </select> }
                    </form>}
                </div>
                <span data-group={this.props.item.name.toLowerCase()} onClick={e => this.handleDelete(e)}>X</span>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    isInventory: state.inventory.isInventory,
    settingsFields: state.inventory.updatedSettings,
    settingsAccount: state.accounts.updatedSettings,
    inventoryGroups: state.store.inventoryGroups,
    inventory: state.inventory,
    accountGroups: state.accounts.accountGroups,
    accounts: state.accounts,
    selectedGroup: state.store.selectedGroup,
    selectedAccount: state.accounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    closeSettings: () => dispatch(closeSettings()),
    loadInventorySettings: (settings) => dispatch(loadInventorySettings(settings)),
    updateInventorySettings: (newField) => dispatch(updateInventorySettings(newField)),
    loadAccountSettings: (settings) => dispatch(loadAccountSettings(settings)),
    updateAccountSettings: (newField) => dispatch(updateAccountSettings(newField)),
    loadInventoryGroups: (allGroupsArray) => dispatch(loadInventoryGroups(allGroupsArray)),
    addGroup: (group) => dispatch(addGroup(group)),
    loadFirstGroup: (groupList) => dispatch(loadFirstGroup(groupList)),
    loadAccounts: (inventoryList) => dispatch(loadAccounts(inventoryList)),
    saveAccountSelection: (selectedGroup) => dispatch(saveAccountSelection(selectedGroup)),
    loadAccountsGroups: (allGroupsArray) => dispatch(loadAccountsGroups(allGroupsArray)),
    loadInventory: (inventoryList) => dispatch(loadInventory(inventoryList)),
    saveSelection: (selectedGroup) => dispatch(saveSelection(selectedGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTabs);
