import React, {Component} from 'react';
import {connect} from 'react-redux';
import uuid4 from 'uuid4';
import db, {firebase, storageRef} from '../../DB/db';
import AllTabs from './AllTabs';
import SelectedGroup from "./SelectedGroup"
import InputIcon from "../UploadIcon"
import { loadInventory, saveSelection, loadInventoryGroups, addGroup, loadFirstGroup, slideInventory, openSettings } from "../../actions/actions";
import SelectItem from "./SelectedItem";
import AllAccountsTabs from "../accounts/AllAccountsTabs";
import {
    addAccount,
    loadAccounts,
    loadAccountsGroups,
    loadSelectedAccount,
    saveAccountSelection, updateAccounts
} from "../../actions/accounts";



class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputIsOpen: false,
            isInventory: true
        };
    }

    componentDidMount() {
        let groupsRef = db.doc('inventoryGroups/array');
        groupsRef.get()
            .then(data => {
                let allGroupsArray = data.data().all;
                this.props.loadInventoryGroups(allGroupsArray);
                allGroupsArray.map((group, i) => {
                    let collRef = db.collection(`inventoryGroups/list/${group.name}`).orderBy('deviceid');
                    if(i === 0) {
                        this.props.saveSelection(group.name);
                    }
                    let groupInfo = {};
                    groupInfo.groupItems = [];
                    groupInfo.name = group.name;
                    collRef.get()
                        .then(querySnapshot => {
                            querySnapshot.forEach(data =>  groupInfo.groupItems = [...groupInfo.groupItems, data.data()])
                        })
                        .then(() => this.props.loadInventory(groupInfo))
                        .then(() => i === 0 ? this.props.loadFirstGroup(groupInfo.groupItems) : null)
                        .catch(err => console.error(err));
                    return true;
                })
            })
            .catch(err => console.error(err));


        let accountRef = db.doc('accountGroups/array');
        accountRef.get()
            .then(data => {
                let allGroupsArray = data.data().all;
                this.props.loadAccountsGroups(allGroupsArray);
                allGroupsArray.map((group, i) => {
                    let collRef = db.collection(`accountGroups/list/${group.name}`);
                    if(i === 0) {
                        this.props.saveAccountSelection(group.name);
                    }
                    let groupInfo = {};
                    groupInfo.groupItems = [];
                    groupInfo.name = group.name;
                    collRef.get()
                        .then(querySnapshot => {
                            querySnapshot.forEach(data =>  groupInfo.groupItems = [...groupInfo.groupItems, data.data()])
                        })
                        .then(() => this.props.loadAccounts(groupInfo))
                        .then(() => i === 0 ? this.props.loadSelectedAccount(groupInfo.groupItems) : null)
                        .catch(err => console.error(err));
                    return true;
                })
            })
            .catch(err => console.error(err));
    }

    openInputField() {
        if(!this.state.inputIsOpen) {
            this.setState({inputIsOpen: true})
        } else {
            this.setState({inputIsOpen: false})
        }
    }

    addTab (e) {
        let inputFieldValue = e.target.value;
        let tabName = document.querySelector('.input-new-tab').value;
        let iconUrl = document.querySelector('.input-new-icon').value;
        let tabPrice = document.querySelector('.input-new-tab-price').value;
        let term = document.querySelector('.input-new-tab-monthly') ? document.querySelector('.input-new-tab-monthly').value : '';
        let validUrl = iconUrl === '' && this.state.url ? this.state.url : iconUrl;
        let groupSplit = tabName.split(' ').join('_').toLowerCase();
        let tabInfo = {};
        tabInfo.name = groupSplit;
        tabInfo.icon = validUrl;
        tabInfo.price = tabPrice;
        tabInfo.term = term;
        tabInfo.display = true;
        if(this.state.isInventory) {
            if(inputFieldValue !== '' && e.which === 13) {
                if(validUrl !== '') {
                    this.setState({inputIsOpen: false});
                    e.target.value = '';
                    this.props.saveSelection(groupSplit);
                    this.props.loadFirstGroup([]);
                    let allGroupsArray = this.props.inventoryGroups;
                    allGroupsArray.push(tabInfo);
                    this.props.loadInventoryGroups(allGroupsArray);
                    this.props.addGroup(tabInfo);
                    let groupsRef = db.doc('inventoryGroups/array');
                    groupsRef.set({all: allGroupsArray})
                        .then(() => console.log('New tab has been saved to the DB'))
                        .catch(err => console.error(err));
                } else {
                    alert('Please add a icon')
                }
            }
        } else {
            if(inputFieldValue !== '' && e.which === 13) {
                if (validUrl !== '') {
                    let groupSplit = inputFieldValue.split(' ').join('_').toLowerCase();
                    this.setState({inputIsOpen: false});
                    e.target.value = '';
                    this.props.saveAccountSelection(groupSplit);
                    this.props.loadSelectedAccount([]);
                    let allGroupsArray = this.props.accountGroups;
                    allGroupsArray.push(tabInfo);
                    this.props.loadAccountsGroups(allGroupsArray);
                    this.props.addAccount(tabInfo);
                    let groupsRef = db.doc('accountGroups/array');
                    groupsRef.set({all: allGroupsArray})
                        .then(() => console.log('New tab has been saved to the DB'))
                        .catch(err => console.error(err));
                } else {
                    alert('Please add a icon')
                }
            }
        }
    }



    handleSlider(e) {
        document.querySelector(".slider").classList.toggle("inventory");
        let label = document.querySelector('.inventory-label');
        label.style.opacity = 0;
        if(label.innerText === 'Accounts') {
            label.style.left = "130px";
            label.style.color = "RGBA(62, 74, 89, 1.00)";
            this.setState({isInventory: true});
            setTimeout(() => {
                label.innerText = 'Inventory';
                label.style.opacity = 1;
            }, 300)
        } else {
            label.style.left = "128px";
            this.setState({isInventory: false});
            setTimeout(() => {
                label.innerText = 'Accounts';
                label.style.opacity = 1;
                label.style.color = "white";
            }, 300)
        }
        this.props.slideInventory();
    }

    uploadFile = (e) => {
        let file = e.target.files[0];
        let timestamp = new Date().getTime();
        let storageFile = storageRef.ref(`tabicons/${timestamp}${file.name}`);
        let task = storageFile.put(file);
        task.on("state_changed",
            snapshot => {
                console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            err => {
                console.log(err)
            },
            (() => {
                task.snapshot.ref.getMetadata().then(data => {
                    let metadata = {
                        fullPath: data.fullPath,
                        graphName: data.name,
                        contentType: data.contentType,
                        created: data.timeCreated,
                        timestamp: data.generation,
                        graphShortName: file.name
                    };
                    console.log(metadata);
                });
                task.snapshot.ref.getDownloadURL()
                    .then(downloadURL => {
                        this.setState({url: downloadURL});
                        document.querySelector('.input-new-icon').value = downloadURL;
                        console.log(downloadURL);
                    });
            }));
    };


    render() {
        let inputStyle = !this.state.inputIsOpen ? {display: 'none'} : {display: 'flex'};
        let addTabStyle = !this.state.inputIsOpen ? {height: '40px'} : {height: '170px'};
        let plusMinus = this.state.inputIsOpen ? '-' : '+';
        let tabs;
        let isInventory = this.props.isInventory;
        let selectedTabInfo = {};
        if(this.props.inventoryGroups) {
            let onlyDisplayedTabs = [];
            this.props.inventoryGroups.map(group => {
                if(group.name.toLowerCase() === this.props.selectedGroup) {
                    selectedTabInfo = {...group};
                }
                return group.display ? onlyDisplayedTabs = [...onlyDisplayedTabs,group] : null
            });
            tabs = onlyDisplayedTabs.map(item => <AllTabs item={item} key={uuid4()} isInventory={isInventory}/>)
        }
        let accountTabs;
        if(this.props.accountGroups) {
            let onlyDisplayedTabs = [];
            this.props.accountGroups.map(group => {
                if(!isInventory) {
                    if(group.name.toLowerCase() === this.props.selectedAccount) {
                        selectedTabInfo = {...group};
                    }
                }
                return group.display ? onlyDisplayedTabs = [...onlyDisplayedTabs,group] : null
            });
            accountTabs = onlyDisplayedTabs.map(item => <AllAccountsTabs item={item} key={uuid4()} isInventory={isInventory}/>)
        }

        return (
            <div className="inventory-container">
                <div className="inventory-header">
                    <span className="inventory-label" onClick={e => this.handleSlider(e)}>Inventory</span>
                    <label className="switch">
                        <input type="checkbox"/>
                        <span className="slider round" onClick={e => this.handleSlider(e)}/>
                    </label>
                </div>
                <div className="inventory-list">
                    <div className="inventory-tabs">
                        <div className="inventory-tabs-database">
                            {this.props.isInventory ? tabs : accountTabs}
                        </div>
                        <div className="plus-tab" style={addTabStyle}>
                            <input style={inputStyle} type="file" id="uploader" onChange={(e) => this.uploadFile(e)}/>
                            <input className="input-new-icon"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}
                                   placeholder="Icon src path"
                            />
                            <input className="input-new-tab"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}
                                   placeholder="Tab Name"
                            />
                            <input className="input-new-tab-price"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}
                                   placeholder="Price"
                            />

                            { !this.props.isInventory && <>
                            <input className="input-new-tab-admin"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}
                                   placeholder="Admin"
                            />
                            <select className="input-new-tab-monthly"
                                   style={inputStyle}
                                   onKeyPress={e => this.addTab(e)}
                            >
                                <option value="monthly">Monthly</option>
                                <option value="annual">Annual</option>
                                <option value="lifetime">Lifetime</option>
                            </select>
                            </>}
                            <span onClick={() => this.openInputField()}>{plusMinus}</span>
                        </div>
                    </div>
                    <SelectedGroup isInventory={this.props.isInventory} selectedTabInfo={selectedTabInfo}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryGroups: state.store.inventoryGroups,
    inventory: state.inventory,
    accountGroups: state.accounts.accountGroups,
    accounts: state.accounts,
    isInventory: state.inventory.isInventory,
    selectedGroup: state.store.selectedGroup,
    selectedAccount: state.accounts.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
    loadInventory: (inventoryList) => dispatch(loadInventory(inventoryList)),
    saveSelection: (selectedGroup) => dispatch(saveSelection(selectedGroup)),
    loadInventoryGroups: (allGroupsArray) => dispatch(loadInventoryGroups(allGroupsArray)),
    addGroup: (group) => dispatch(addGroup(group)),
    loadFirstGroup: (groupList) => dispatch(loadFirstGroup(groupList)),
    loadAccounts: (inventoryList) => dispatch(loadAccounts(inventoryList)),
    saveAccountSelection: (selectedGroup) => dispatch(saveAccountSelection(selectedGroup)),
    loadAccountsGroups: (allGroupsArray) => dispatch(loadAccountsGroups(allGroupsArray)),
    addAccount: (group) => dispatch(addAccount(group)),
    loadSelectedAccount: (groupList) => dispatch(loadSelectedAccount(groupList)),
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo)),
    slideInventory: () => dispatch(slideInventory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
