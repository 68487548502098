import React, {Component} from 'react';
import {connect} from 'react-redux';
import ModernDatepicker from 'react-modern-datepicker';
import Calendar from 'react-calendar';
import DatePicker from 'react-datepicker';
import * as moment from 'moment/moment';
import {
    saveFirstForm,
    closeModal,
    loadEmployees,
    removeEmployee,
    loadCandidates
} from '../../actions/actions'
import  { getDepartmentColor } from '../utils/utils';
import db from "../../DB/db";
import {OAuth2Client} from "google-auth-library";
import axios from 'axios';


class NewEmployee extends Component {
    state = {
        formOne: true,
        formTwo: false,
        workEmail: '',
        date: new Date(),
        startDate: '',
        isEmployee: true
    };



    handleChange(date) {
        this.setState({
            startDate: date,
        });
    }

    handleStatusChange(e) {
        if(e.target.value === 'employees') {
            this.setState({isEmployee: true})
        } else {
            this.setState({isEmployee: false})
        }
    }

    createEmail() {
        /*const SCOPES = ['https://www.googleapis.com/auth/admin.directory.user'];
        db.doc('token/apiKey').get().then(key => {
            let CONFIG_CLIENT_ID = key.data().client_id;
            let CONFIG_CLIENT_SECRET = key.data().client_secret;
            let FUNCTIONS_REDIRECT = key.data().redirects;
            const functionsOauthClient = new OAuth2Client(CONFIG_CLIENT_ID, CONFIG_CLIENT_SECRET,
                FUNCTIONS_REDIRECT);
            return window.location.href = functionsOauthClient.generateAuthUrl({
                access_type: 'offline',
                scope: SCOPES,
                prompt: 'consent',
            });
        });*/
        axios.get('https://fa-aria.firebaseapp.com/authgoogleapi')
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    nextForm() {
        let allInput = document.querySelectorAll('.form-control');
        let isEmpty = false;
        allInput.forEach(input => input.value === '' ? isEmpty = true : null);
        if(!isEmpty) {
            if ((this.state.isEmployee && this.state.startDate !== '') || (!this.state.isEmployee)) {
                let info = {};
                let firstName = document.querySelector('#firstName').value;
                info.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
                let lastName = document.querySelector('#lastName').value;
                let allLastName = '';
                lastName.split(' ').map(word => {
                    allLastName = allLastName + ' ' + word.charAt(0).toUpperCase() + word.slice(1)
                });
                info.lastName = allLastName;
                info.personalEmail = document.querySelector('#personalEmail').value;
                info.workEmail = `${document.querySelector('#workEmail').value}@freeagentsoftware.com`;
                info.phone = document.querySelector('#phone').value;
                info.officeLocation = document.querySelector('#officeLocation').value;
                info.department = this.props.department;
                info.color = getDepartmentColor(this.props.department);
                info.companyRole = document.querySelector('#companyRole').value;
                info.timestamp = new Date().getTime();
                info.dateCreated = moment().format('LLLL');
                info.dateJoined = this.state.startDate;

                let employeeRef = db.doc(`employees/${info.workEmail}`);
                let lastEmployeeInfo = db.doc('token/newEmployee');
                employeeRef.get().then(doc => {
                    if (!doc.exists) {
                        employeeRef.set(info)
                            .then(() => this.props.saveFirstForm(info))
                            .catch(err => console.error(err));
                        let employeeInfo = {};
                        employeeInfo.department = this.props.department;
                        employeeInfo.employee = info;
                        lastEmployeeInfo.set(info)
                            .then(() => console.log('new employee saved'))
                            .catch(err => console.error(err));
                        let withNewEmployee = [...this.props.employees, {...employeeInfo.employee}];
                        this.props.loadEmployees(withNewEmployee);
                        let onlyDepartment = [];
                        withNewEmployee.map(employee => employee.department === this.props.department ? onlyDepartment.push(employee) : null);
                        this.props.removeEmployee({department: this.props.department, newArray: onlyDepartment});
                        this.props.closeModal();
                    } else {
                        alert('Employee with this email already exists')
                    }
                })
            } else {
                alert('Please select the date')
            }
        } else {
            alert('Please fill all the fields');
        }
    }

    closeForm() {
        this.props.closeModal();
    }

    changeFirstNameEmail(e) {
        this.setState({workEmail: `${e.target.value.toLowerCase()}`})
    }

    changeWorkEmail(e) {
        this.setState({
            workEmail: `${e.target.value.toLowerCase()}`
        });
    }

    render() {
        let color = getDepartmentColor(this.props.department);
        let buttonBackground = {
            backgroundImage: `linear-gradient(${color}, ${color})`,
            boxShadow: `1px 1px 3px 1px ${color}`
        };
        return (
            <div className="modal-container">
               <div className="modal-form">
                   { this.state.formOne &&
                   <form>
                           <div className="form-group">
                               <label htmlFor="firstName">First Name</label><br/>
                               <input type="text" className="form-control" id="firstName"
                               onChange={(e) => this.changeFirstNameEmail(e)}
                               />
                           </div>
                           <div className="form-group">
                               <label htmlFor="lastName">Last Name</label><br/>
                               <input type="text" className="form-control" id="lastName"/>
                           </div>
                           <div className="form-group">
                               <label htmlFor="personalEmail">Personal Email</label><br/>
                               <input type="text" autoComplete="false" className="form-control" id="personalEmail"/>
                           </div>
                           <div className="form-group">
                               <label htmlFor="workEmail">Work Email</label><br/>
                               <input type="text" className="form-control" id="workEmail"
                               value={this.state.workEmail}
                               onChange={(e) => this.changeWorkEmail(e)}
                               />
                               <span className="emailExtension">@freeagentsoftware.com</span>
                           </div>
                           <div className="form-group">
                               <label htmlFor="phone">Phone Number</label><br/>
                               <input type="text" className="form-control" id="phone"/>
                           </div>
                           <div className="form-group">
                               <label htmlFor="officeLocation">Office Location</label><br/>
                               <input type="text" className="form-control" id="officeLocation"/>
                           </div>
                       <div className="form-group">
                           <label htmlFor="companyRole">Company Role</label><br/>
                           <input type="text" className="form-control" id="companyRole"/>
                       </div>
                       <div className="form-group">
                           <label htmlFor="companyRole">Date Started</label><br/>
                           <ModernDatepicker
                               date={this.state.startDate}
                               format={'MMM DD, YYYY'}
                               showBorder
                               onChange={date => this.handleChange(date)}
                               placeholder={'Select a date'}
                           />
                       </div>
                           <div className="btn btn-default" style={buttonBackground} onClick={() => this.nextForm()}>Save</div>
                           <section onClick={() => this.closeForm()}>Close</section>
                       </form>}
               </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAdmin: state.store.isAdmin,
    department : state.store.department,
    employees: state.store.allEmployees,
    candidates: state.store.allCandidates,
});

const mapDispatchToProps = (dispatch) => ({
    loadEmployees: (employees) => dispatch(loadEmployees(employees)),
    removeEmployee: (info) => dispatch(removeEmployee(info)),
    saveFirstForm: (info) => dispatch(saveFirstForm(info)),
    closeModal: () => dispatch(closeModal()),
    loadCandidates: (candidates) => dispatch(loadCandidates(candidates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEmployee);
