import React, {Component} from 'react';
import {connect} from 'react-redux';
import uuid4 from 'uuid4';
import SelectedAccountItem from "./SelectedAccountItem";
import db, { firebase } from '../../DB/db';
import { updateAccounts } from "../../actions/accounts";



class SelectedAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    static openForm(e) {
        document.querySelector('#addAccountItemForm').style.display = 'flex';
    }
    closeForm(e) {
        document.querySelector('#addAccountItemForm').style.display = 'none';
    }
    addItem(e) {
        let allInfo = {};
        let allInputValues = document.querySelectorAll('#addAccountItemForm > input');
        allInputValues.forEach(field => {
            allInfo[field.name] = field.value;
            return field.value = '';
        });
        let docRef = db.doc(`accountGroups/list/${this.props.selectedAccount}/${allInfo.serial}`);
        docRef.set(allInfo).then(() => console.log('saved to the DB')).catch(err => console.error(err));
        let itemInfo = {};
        itemInfo.name = this.props.selectedAccount;
        itemInfo.info = allInfo;
        this.props.updateAccounts(itemInfo);
    }

    render() {
         let inventory;
         if(this.props.activeList) {
             inventory = this.props.activeList.map((item, i) => <SelectedAccountItem item={item} key={uuid4()} />)
         }

        return (
            <div className="accounts-selected-tab-content">
                {inventory}
                <form id="addAccountItemForm">
                    <input id="serial" name="serial" placeholder="Serial"/>
                    <input id="brand" name="brand" placeholder="Brand"/>
                    <input id="model" name="model" placeholder="Model"/>
                    <input id="model" name="assigned_to" placeholder="AssignedTo"/>
                    <input id="date" name="date" placeholder="Date"/>
                    <div id="addButtonAccounts" onClick={e => this.addItem(e)}>Add</div>
                    <div id="addButtonAccounts" onClick={e => this.closeForm(e)}>Close</div>
                </form>
                <div className="accounts-plus-add-item" onClick={(e) => SelectedAccount.openForm(e)}>+</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.inventory,
    activeList: state.accounts.activeAccountsList,
    selectedAccount: state.accounts.selectedAccount,
    accounts: state.accounts
});

const mapDispatchToProps = (dispatch) => ({
    updateAccounts: (itemInfo) => dispatch(updateAccounts(itemInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAccount);
