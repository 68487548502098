import React, {Component} from 'react';
import {connect} from 'react-redux';
import db from "../../DB/db";
import {
    editAssignedToInventory,
    editInventoryField,
    removeAssignedToInventory,
    updateInventory
} from "../../actions/actions";
import {editAccountField} from "../../actions/accounts";


class SelectedAccountItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false
        };
    }



    componentDidMount() {
        let itemInfo = this.props.item;
        let onlyWithDisplay = [];
        this.props.settingsAccount.map(field => {
            if(field.display) {
                return onlyWithDisplay = [...onlyWithDisplay,field.label.toLowerCase()];
            }
        });
        onlyWithDisplay.map((key) => {
            this.setState({[key]:itemInfo[key]});
        });
        if(itemInfo.timeframe) {
            this.setState({timeframe:itemInfo.timeframe});
        }
    }

    handleInputChange(e) {
        this.setState({[e.target.name]:e.target.value});
    }

    handleSelectChange(e) {
        this.setState({assignedto: e.target.value, edit: true});
    }


    handleEdit() {
        if(this.state.editing) {
            let allInputs = document.querySelectorAll('.selected-item > input');
            let newField = {};
            allInputs.forEach(input => {
                newField[input.name.toLowerCase()] = input.value;
            });
            if(document.querySelector('.selected-item > select')) {
                newField.assignedto = document.querySelector('.selected-item > select').value;
            }
            newField.timeframe = this.props.item.timeframe;
            let accountsGroup = this.props.accounts[this.props.selectedAccount];
            accountsGroup.splice(this.props.index, 1, newField);
            let updatedGroupInfo = {selected:this.props.selectedGroup, editedArray: accountsGroup};
            this.props.editAccountField(updatedGroupInfo);

            let info = {email:this.props.item.email, timeframe: this.props.item.timeframe, type:'account', newField};
            this.props.editAssignedToInventory(info);

            let colRef = db.collection(`accountGroups/list/${this.props.selectedAccount}`)
                .where("timeframe", "==", this.props.item.timeframe);
            colRef.get().then(data => {
                data.forEach(data => {
                    let docRef = db.collection(`accountGroups/list/${this.props.selectedAccount}`).doc(data.id);
                    return docRef.update({
                        ...newField
                    });
                })
            });
        }
        this.setState({editing: !this.state.editing});
    }

    handleDelete() {
        let accountsGroup = this.props.accounts[this.props.selectedAccount];
        accountsGroup.splice(this.props.index, 1);
        let updatedGroupInfo = {selected:this.props.selectedAccount, editedArray: accountsGroup};
        this.props.editAccountField(updatedGroupInfo);
        let colRef = db.collection(`accountGroups/list/${this.props.selectedAccount}`)
            .where("timeframe", "==", this.props.item.timeframe);
        colRef.get().then(data => {
            data.forEach(data => {
                let docRef = db.collection(`accountGroups/list/${this.props.selectedAccount}`).doc(data.id);
                return docRef.delete().then(() => console.log("doc deleted")).catch(err => console.log(err));
            })
        });
        let accountRef = db.collection(`employees/${this.props.item.email}/accountsAssigned`).where("timeframe", "==", this.props.item.timeframe);
        accountRef.get().then(queryData => queryData.forEach(data => {
            console.log(data.id);
            db.collection(`employees/${this.props.item.email}/accountsAssigned`).doc(data.id).delete()
                .then(() => console.log('data has been deleted'))
                .catch(err => console.error(err));
        }));
        let info = {email:this.props.item.email, timeframe: this.props.item.timeframe, type: 'account'};
        this.props.removeAssignedToInventory(info);
    }

    render() {
        let editIcon = require("../../assets/edit.svg");
        let deleteIcon = require("../../assets/delete.svg");
        let iconStyle = {height: "20px", width:"auto", marginRight: "10px"};
        let itemInfo = this.props.item;
        let allItems;
        let editItems;
        let onlyWithDisplay = [];

        let assignedOptions;
        if(this.props.allEmployees) {
            assignedOptions =  this.props.allEmployees.map((employee,i) => (<option key={i}>{employee.firstName} {employee.lastName}</option>))
        }


        this.props.settingsAccount.map(field => {
            if(field.display) {
                return onlyWithDisplay = [...onlyWithDisplay,field.label.toLowerCase()];
            }
        });
        allItems = onlyWithDisplay.map((key,i) => {
            return (<span key={i} className={key}>{key === 'price'? itemInfo[key] + '$' : itemInfo[key]}</span>)
        });
        editItems = onlyWithDisplay.map((key,i) => {
            let style = onlyWithDisplay.length === i + 1 ? { borderRadius: "0 2px 2px 0", marginRight: "10px"} : {};
            let returnField;
            if(key.replace(' ', '').toLowerCase() === 'assignedto') {
                returnField = (<select
                    name="assignedto"
                    key={i}
                    onChange={(e) => this.handleSelectChange(e)}
                    value={this.state.assignedto ? this.state.assignedto : ''}
                    style={style}>{assignedOptions}</select>)
            } else {
                returnField = (<input name={key} value={this.state[key] ? this.state[key] : ''} key={i} onChange={e => this.handleInputChange(e)} style={style}/>)
            }
            return returnField;
        });
        return (
            <div className="selected-item">
                {!this.state.editing ? allItems : editItems}
                <img className="iconsEditDelete" alt="" src={editIcon} style={iconStyle} onClick={() => this.handleEdit()}/>
                <img className="iconsEditDelete" alt="" src={deleteIcon} style={iconStyle} onClick={() => this.handleDelete()}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.store.allInventory,
    inventory: state.inventory,
    selectedGroup: state.store.selectedGroup,
    settingsFields: state.inventory.updatedSettings,
    settingsAccount: state.accounts.updatedSettings,
    inventoryGroups: state.store.inventoryGroups,
    accountGroups: state.accounts.accountGroups,
    accounts: state.accounts,
    isInventory: state.inventory.isInventory,
    selectedAccount: state.accounts.selectedAccount,
    allEmployees: state.store.allEmployees
});

const mapDispatchToProps = (dispatch) => ({
    updateInventory: (itemInfo) => dispatch(updateInventory(itemInfo)),
    editInventoryField: (updatedGroupInfo) => dispatch(editInventoryField(updatedGroupInfo)),
    editAccountField: (updatedGroupInfo) => dispatch(editAccountField(updatedGroupInfo)),
    removeAssignedToInventory: (info) => dispatch(removeAssignedToInventory(info)),
    editAssignedToInventory: (info) => dispatch(editAssignedToInventory(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAccountItem);


/*class SelectedAccountItem extends Component {

    render() {
        let itemInfo = this.props.item;
        let allItems;
        let onlyWithDisplay = [];
        this.props.settingsAccount.map(field => {
            if(field.display) {
                return onlyWithDisplay = [...onlyWithDisplay,field.label.toLowerCase()];
            }
        });
        allItems = onlyWithDisplay.map((key,i) => {
            return (<span key={i}>{itemInfo[key]}</span>)
        });

        return (
            <div className="selected-item">
                {allItems}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    inventoryList: state.store.allInventory,
    selectedGroup: state.store.selectedGroup,
    settingsAccount: state.accounts.updatedSettings
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAccountItem);*/
