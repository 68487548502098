
export const loadAccounts = (accountsList) => ({
    type: 'LOAD_ACCOUNTS',
    accountsList
});

export const updateAccounts = (accountInfo) => ({
    type: 'UPDATE_ACCOUNTS',
    accountInfo
});



export const loadAccountsGroups = (accountGroups) => ({
    type: 'LOAD_ACCOUNT_GROUPS',
    accountGroups
});

export const loadSelectedAccount = (accountList) => ({
    type: 'LOAD_SELECTED_ACCOUNT',
    accountList
});



export const addAccount = (account) => ({
    type: 'ADD_ACCOUNT',
    account
});

export const saveAccountSelection = (selectedAccount) => ({
    type: 'SAVE_SELECTION_ACCOUNT',
    selectedAccount
});


export const loadAccountSettings = (settings) => ({
    type: 'LOAD_ACCOUNT_SETTINGS',
    settings
});

export const updateAccountSettings = (newField) => ({
    type: 'UPDATE_ACCOUNT_SETTINGS',
    newField
});

export const editAccountField = (updatedGroupInfo) => ({
    type: 'EDIT_ACCOUNT_FIELD',
    updatedGroupInfo
});
