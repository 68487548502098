export default (state = {
    activeAccountsList: [],
    accountGroups: [],
    selectedAccount: '',
    settingFields: [],
    lastItemAdded:{}
}, action) => {
    switch (action.type) {
        case 'LOAD_ACCOUNTS':
            let copyState = {...state};
            copyState[action.accountsList.name.toLowerCase()] = action.accountsList.groupItems;
            return {
                ...copyState
            };
        case 'UPDATE_ACCOUNTS':
            let accountsUpdateState = {...state};
            let word = '';
            let lastElementWord = '';
            Object.keys(action.accountInfo.info).map(key => key !== 'timeframe' ? word = word + action.accountInfo.info[key] : null);
            Object.keys(state.lastItemAdded).map(key => key !== 'timeframe' ? lastElementWord = lastElementWord + state.lastItemAdded[key] : null);
            if(word.trim() !== '' && word !== lastElementWord) {
                if (!state.activeAccountsList) {
                    accountsUpdateState.activeAccountsList = [action.accountInfo.info];
                } else {
                    accountsUpdateState.activeAccountsList = [...state.activeAccountsList, action.accountInfo.info];
                }
                if (!accountsUpdateState[action.accountInfo.name] && action.accountInfo.name) {
                    accountsUpdateState[action.accountInfo.name.toLowerCase()] = [action.accountInfo.info];
                } else {
                    accountsUpdateState[action.accountInfo.name.toLowerCase()] = [...accountsUpdateState[action.accountInfo.name.toLowerCase()], action.accountInfo.info];
                }
                accountsUpdateState.lastItemAdded = action.accountInfo.info;
            }
            return {
                ...accountsUpdateState
            };
        case 'LOAD_ACCOUNT_GROUPS':
            return {
                ...state,
                accountGroups: action.accountGroups
            };
        case 'LOAD_SELECTED_ACCOUNT':
            return {
                ...state,
                activeAccountsList: action.accountList
            };
        case 'SAVE_SELECTION_ACCOUNT':
            return {
                ...state,
                selectedAccount: action.selectedAccount,
            };
        case 'LOAD_ACCOUNT_SETTINGS':
            return {
                ...state,
                settingsFields: action.settings,
                updatedSettings: action.settings
            };
        case 'UPDATE_ACCOUNT_SETTINGS':
            let settingsStateCopy = {...state};
            let settingFields = state.updatedSettings || [];
            let onlyLabels = settingFields.map(field => field.label);
            if(!onlyLabels.includes(action.newField.label)) {
                settingsStateCopy.updatedSettings = [...settingFields, action.newField];
            } else {
                settingsStateCopy.updatedSettings = [...settingFields];
            }
            return {
                ...settingsStateCopy
            };
        case 'EDIT_ACCOUNT_FIELD':
            let editingCopy = {...state};
            editingCopy[action.updatedGroupInfo.selected] = action.updatedGroupInfo.editedArray;
            editingCopy.refresh = !editingCopy.refresh;
            editingCopy.activeAccountsList = [...editingCopy[action.updatedGroupInfo.selected]];
            return {
                ...editingCopy
            };
        default:
            return state;
    }
};
